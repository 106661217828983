import { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from 'react-number-format';

const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
const lerp = (start, end, t) => start * (1 - t) + end * t;

const AnimatedNumber = ({ startValue, endValue, duration = 2000 }) => {
  const [currentValue, setCurrentValue] = useState(startValue);

  useEffect(() => {
    let startTimestamp;
    let animationFrameId;

    const animate = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;

      const ratio = Math.min(progress / duration, 1);

      const animatedValue = lerp(startValue, endValue, easeInOutQuad(ratio));

      setCurrentValue(Math.floor(animatedValue));

      if (progress < duration) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
      setCurrentValue(endValue);
    };
  }, [endValue, duration, startValue]);

  return <NumberFormat value={currentValue} displayType={'text'} thousandSeparator={true} decimalSeparator={false} />;
};

function ARBTotalDelegatorQueryFeeRebates() {
  const [totalDelegatorQueryFeeRebates, setTotalDelegatorQueryFeeRebates] = useState(0);

  useEffect(() => {
    const fetchTotalDelegatorQueryFeeRebates = async () => {
      try {
        const response = await axios.post(
          "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-arbitrum",
          {
            query: 
              `{
                graphNetworks(first: 1, orderBy: epochCount, orderDirection: desc) {
                  
                  curatorCount
                  activeCuratorCount
                  totalTokensSignalled
                  totalCuratorQueryFees


                  delegatorCount
                  activeDelegatorCount

                  delegationCount
                  activeDelegationCount

                  totalDelegatedTokens
                  
                  

                  indexerCount
                  stakedIndexersCount
                  totalTokensStaked
                  totalIndexingIndexerRewards
                  totalIndexerQueryFeeRebates
                  totalIndexerQueryFeesCollected
                  
                  totalTokensAllocated

                  subgraphCount
                  subgraphDeploymentCount
                  activeSubgraphCount
                  
                  

                  delegationUnbondingPeriod
                  
                  currentEpoch
                  totalGRTBurned
                  totalGRTMinted
                  totalGRTWithdrawn
                  totalGRTDeposited
                  totalQueryFees
                  totalSupply

                  totalDelegatorQueryFeeRebates
                  totalIndexingDelegatorRewards
                  

                 
                }
              }`
            
          }
        );
        const NewTotalDelegatorQueryFeeRebates = (response.data.data.graphNetworks[0].totalDelegatorQueryFeeRebates/1000000000000000000);
        setTotalDelegatorQueryFeeRebates(NewTotalDelegatorQueryFeeRebates);

      } catch (error) {
        console.error(error);
      }
    };

    fetchTotalDelegatorQueryFeeRebates();
  }, []);

  return (
    <div>
      <h4>Total Recieved Query Fees<br />
        <AnimatedNumber startValue={0} endValue={totalDelegatorQueryFeeRebates} /> GRT
      </h4>
    </div>
  );
}

export default ARBTotalDelegatorQueryFeeRebates;