import React from 'react';
import SearchFilter from './SearchFilter';
import './DocumentationFinderHome.css';
import DocumentationFinderNavbar from '../navbars/DocumentationFinderNavbar';
import AdvocateFooter from '../footer/AdvocateFooter';

const DocumentationFinderHome = () => {
  const data = [
    'Advocate',
    'GraphAdvocateDAO',
    'CommunityCare',
    'ContentCreator',
    'CodeOfConduct',
    'EventEvangelist',
    'Roles',
    'Mission',
    'TechnicalTeacher',
    'TextTranslator',
  ];

  return (
    <>
    <div className="privacy-policy">
      <DocumentationFinderNavbar></DocumentationFinderNavbar>
      <div className="documentation-finder">
      <h1>Documentation Finder</h1>
      <p>Use the search function to easily find Graph AdvocatesDAO documentation. If you take the quiz and get an answer wrong, you will have been sent here with a word to search to aid you learn the correct answer. Use this as a revision aid before your next practice test.</p>
      <SearchFilter data={data} />
      <br />
      <div className="new-button QB-about-page-subtitle bottom-button">
        <a className="chatbot-link" href="https://mb3kald91rd.typeform.com/to/V7XGWgMF" target="_blank" rel="noreferrer">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <strong>Improvements / Feedback Form</strong>
      </a>
  </div>
      </div>
    </div>
     <AdvocateFooter className="chatbot-footer-mobile" />
    </>
  );
};

export default DocumentationFinderHome;
