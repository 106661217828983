import { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from 'react-number-format';

function ARBIndexerCount() {
  const [indexerCount, setIndexerCount] = useState(0);

  useEffect(() => {
    const fetchIndexerCount = async () => {
      try {
        const response = await axios.post(
          "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-arbitrum",
          {
            query: 
              `{
                graphNetworks(first: 1, orderBy: epochCount, orderDirection: desc) {
                  curatorCount
                  activeCuratorCount
                  totalTokensAllocated
                  
                  delegatorCount
                  activeDelegatorCount
                  delegationCount
                  activeDelegationCount
                  totalDelegatedTokens
                  
                  indexerCount
                  stakedIndexersCount
                  totalTokensStaked
                
                  
                  totalTokensSignalled
                  
                  subgraphCount
                  subgraphDeploymentCount
                  activeSubgraphCount
                  
                  
                  
                  totalGRTBurned
                  totalGRTMinted
                 
                  totalQueryFees
                  totalSupply
                  
                  assetHolders
                  
                  delegationUnbondingPeriod
                  currentEpoch
                  epochCount
                
                  
                  totalSignalledTokensTransferredToL2
                  totalTokensSignalledAutoMigrate
                  totalTokensSignalledDirectly
                  totalTokensStakedTransferredToL2
                 
                }
              }`
            
          }
        );
        setIndexerCount(response.data.data.graphNetworks[0].indexerCount);
      } catch (error) {
        console.error(error);
      }
    };

    fetchIndexerCount();
  }, []);

  return <div><h4>Indexer Count<br></br><NumberFormat value={indexerCount} displayType={'text'} thousandSeparator={true} decimalSeparator={false}/></h4></div>;

}

export default ARBIndexerCount;