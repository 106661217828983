import React, { useState } from 'react';
import '../../App.css';
import AdvocateReadyNavbar from '../navbars/AdvocateReadyNavbar';
import AdvocateFooter from '../footer/AdvocateFooter';

function AdvocateReady() {
  const [parametersVisible, setParametersVisible] = useState(false);

  const toggleParametersVisibility = () => {
    setParametersVisible(!parametersVisible);
  };

  return (
    <>
      <AdvocateReadyNavbar />
      <div className="question-bank-container">
        <div className="QB-text-container">
        <h2 className="QB-about-page-subtitle">
            <strong>You are viewing a LIVE SHOWCASE of a proposed app feature which I hope will pass within the AdvocatesDAO this time round. If so, the whole feature will be open to be used by all community members in the near future with the prospect of making it multi-lingual. As always, please provide open and honest feedback! See <a className="chatbot-link" href="https://forum.graphadvocates.com/t/grant-proposal-are-you-advocate-ready/1074" target="_blank" rel="noreferrer">here</a>. Enjoy!</strong>
          </h2>
          <hr />
          <h2 className="QB-about-page-subtitle">
            <strong>Are you Advocate Ready?</strong>
          </h2>
          <br />
          <hr className="QB-about-hr" />
          <h2 className="QB-about-page-subtitle">
            This feature will assist you in becoming a higher calibre of Graph Advocate. If you are a community member
            currently sat on the fence, this feature is for you! It will aid you in learning what it takes to become a
            Graph Advocate, allowing you to test yourself before applying for the role officially. Together we can
            onboard the next wave of Graph Advocates from this amazing community.
          </h2>
          <br />
          <br />
          <h2 className="QB-about-page-subtitle">
            If you think your community would benefit from this feature being multi-lingual, please message me via{' '}
            <a className="chatbot-link" href="https://t.me/GRTDataHub" target="_blank" rel="noreferrer">
              Telegram
            </a>{' '}
            or{' '}
            <a className="chatbot-link" href="https://twitter.com/GRTCrypto" target="_blank" rel="noreferrer">
              X
            </a>
            . I work with many amazing Graph Advocate translators and I will try and make that happen!
          </h2>
          <hr />
          <h2 className="QB-about-page-subtitle">
            <strong>GraphAdvocatesDAO Mission:</strong> The mission of the Graph AdvocatesDAO is to drive greater
            participation in building a decentralized web3 and grow The Graph ecosystem by supporting community-based
            initiatives and through contributions of Graph Advocates.
          </h2>
          <br />
          <h3 className="expand" onClick={toggleParametersVisibility}>
            What is a Graph Advocate? Click me to find out!
          </h3>
          {parametersVisible && (
            <>
              <br />
              <div className="advocate-details">
                <p>The Advocates program is a community-led and community-driven initiative serving as a portal into web3 for people all across the world. The Graph AdvocatesDAO is the community governing body that oversees membership and activity of the Graph Advocates, as well as community grants in The Graph ecosystem.</p>

<p>The Graph Advocates are here to empower people passionate about web3 and decentralization, and enable them to drive community development and growth. The program is a critical component in the decentralization of The Graph’s community, as Advocates will take the lead in creating educational resources, hosting local events, assisting with technical support, and making Graph resources available across the world.</p>

<p>Advocates are given the opportunity to coordinate on a broad scale, channeling their collective passion for web3 and decentralization into an educational force for those new to blockchain, web3, or The Graph. Becoming an Advocate offers flexibility: Advocates can focus on different roles in the community based on their interests and abilities. Echoing The Graph community’s vision of an internet without gatekeepers, Advocates of all walks of life are encouraged to pursue what motivates them.</p>

<p>Becoming an Advocate offers flexibility: Advocates can focus on different roles in the community based on their interests and abilities. Echoing The Graph community’s vision of an internet without gatekeepers, Advocates of all walks of life are encouraged to pursue what motivates them. There are currently six different Advocate roles to embody, visit the Documentation Finder to learn more!</p>
</div>
            </>
          )}
          <br />
          <div className="ENG-button QB-about-page-subtitle">
            <a className="chatbot-link" href="/DocumentationFinderHome" target="_blank" rel="noreferrer">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Documentation Finder
            </a>
          </div>
          <br />
          <div className="ENG-button QB-about-page-subtitle">
            <a className="chatbot-link" href="/flashCards" target="_blank" rel="noreferrer">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Flash Cards
            </a>
          </div>
          <br />
          <div className="ENG-button QB-about-page-subtitle">
            <a className="chatbot-link" href="/Quiz" target="_blank" rel="noreferrer">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Mock Quiz
            </a>
          </div>
          <br />
          <div className="ENG-button QB-about-page-subtitle">
            <a className="chatbot-link" href="/FinalExam" target="_blank" rel="noreferrer">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Final Exam
            </a>
          </div>
          <br />
          <br />
          <hr className="QB-about-hr" />
          <h2 className="QB-about-page-subtitle">
            Please share with other great GRT community members that you think would like this feature, let's get them
            involved and help them on their path to become a Graph Advocate.
          </h2>
          <br />
          <div className="new-button QB-about-page-subtitle bottom-button">
        <a className="chatbot-link" href="https://mb3kald91rd.typeform.com/to/V7XGWgMF" target="_blank" rel="noreferrer">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <strong>Improvements / Feedback Form</strong>
      </a>
  </div>
        </div>
      </div>
      <AdvocateFooter className="chatbot-footer-mobile" />
    </>
  );
}

export default AdvocateReady;
