import React, { useState, useEffect } from 'react';
import '../../App.css';
import FlashcardsForm from './FlashcardsForm';
import FlashcardsNavbar from '../navbars/FlashcardsNavbar';
import FlashcardsData from './FlashcardsData';
import AdvocateFooter from '../footer/AdvocateFooter';

function FlipCard({ question, answer, flipped, onClick, showAnswer }) {
  return (
    <div className={`flip-card${flipped ? ' flipped' : ''}`} onClick={onClick}>
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <p className="card-content">{question}</p>
        </div>
        <div className="flip-card-back">
          <p className="card-content">{showAnswer ? answer : ''}</p>
        </div>
      </div>
    </div>
  );
}

function FlashCards() {
  const [topic, setTopic] = useState(null);
  const [difficulty, setDifficulty] = useState(null);
  const [showCards, setShowCards] = useState(false);
  const [showFlashcardsForm, setShowFlashcardsForm] = useState(true);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [flipped, setFlipped] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  const handleTopicChange = (selectedOption) => {
    setTopic(selectedOption);
  };

  const handleDifficultyChange = (selectedOption) => {
    setDifficulty(selectedOption);
  };

  const flashcardsData = FlashcardsData();

  const [selectedTopic, setSelectedTopic] = useState('');
  const [selectedDifficulty, setSelectedDifficulty] = useState('');

  useEffect(() => {
    const filteredQuestions = flashcardsData.questions.filter(
      (question) =>
        question.topic === topic && question.difficulty === difficulty
    );

    const shuffledQuestions = filteredQuestions.sort(() => Math.random() - 0.5);
    const selectedQuestions = shuffledQuestions.slice(0, 100); 
    // edit 0,100 for number of Qs

    setSelectedQuestions(selectedQuestions);
  }, [topic, difficulty]);

  const handleStartClick = () => {
    setShowCards(true);
    setShowFlashcardsForm(false);
  };

  const handlePlayAgainClick = () => {
    setTopic(null);
    setDifficulty(null);
    setCurrentCardIndex(0);
    setShowFlashcardsForm(true);
    setShowCards(false);
  };

  const handleCardClick = () => {
    setShowAnswer(true);
    setFlipped(!flipped);
  };

  const handleNextClick = () => {
    if (currentCardIndex < selectedQuestions.length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
      setShowAnswer(false);
      setFlipped(false);
    }
  };

  const handlePrevClick = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
      setShowAnswer(false);
      setFlipped(false);
    }
  };

  return (
    <>
    <div className="privacy-policy">
      <FlashcardsNavbar></FlashcardsNavbar>
      {showFlashcardsForm && (
     <FlashcardsForm
  selectedTopic={selectedTopic}
  selectedDifficulty={selectedDifficulty}
  topicOptions={flashcardsData.topicOptions}
  handleTopicChange={handleTopicChange}
  handleDifficultyChange={handleDifficultyChange}
  handleStartClick={handleStartClick}
/>

      )}
      {showCards && (
        <div className="flashcards-container">
          <div className="flashcards-controls">
            <button
              className="flashcards-button"
              onClick={handlePrevClick}
              disabled={currentCardIndex === 0}
            >
              Prev
            </button>
            <button
              className="flashcards-button"
              onClick={handleNextClick}
              disabled={currentCardIndex === selectedQuestions.length - 1}
            >
              Next
            </button>
            <button
              className="flashcards-button"
              onClick={handlePlayAgainClick}
            >
              Learn more
            </button>
          </div>
          <div className="flip-card-container">
            <FlipCard
              question={selectedQuestions[currentCardIndex].question}
              answer={selectedQuestions[currentCardIndex].answer}
              flipped={flipped}
              onClick={handleCardClick}
              showAnswer={showAnswer}
            />
          </div>
          <br></br>
          <p className="flashcards-instructions">
              Click the Flashcard to flip it!
            </p>
        </div>
      )}
      <div className="new-button QB-about-page-subtitle bottom-button">
        <a className="chatbot-link white" href="https://mb3kald91rd.typeform.com/to/V7XGWgMF" target="_blank" rel="noreferrer">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <strong>Improvements / Feedback Form</strong>
      </a>
  </div>
    </div>
    <AdvocateFooter className="chatbot-footer-mobile" />
    </>
  );
}

export default FlashCards;
