import React, { useState, useEffect } from 'react';
import '../../App.css';
import QuizForm from './QuizForm';
import QuizNavbar from "../navbars/QuizNavbar";
import { QuizData } from './QuizData';
import Switch from 'react-switch';
import AdvocateFooter from '../footer/AdvocateFooter';

const Quiz = () => {
  const DEFAULT_TOPIC = '';
  const DEFAULT_DIFFICULTY = '';

  const [showWrongAnswerPopup, setShowWrongAnswerPopup] = useState(false);
  const [topic, setTopic] = useState(DEFAULT_TOPIC);
  const [difficulty, setDifficulty] = useState(DEFAULT_DIFFICULTY);
  const [score, setScore] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuizForm, setShowQuizForm] = useState(true);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showScoreTimer, setShowScoreTimer] = useState(true);
  const [selectedQuestions, setSelectedQuestions] = useState([]);

  useEffect(() => {
    const filteredQuestions = quizData.questions
      .filter(
        (question) =>
          question.topic === topic && question.difficulty === difficulty
      )
      .sort(() => Math.random() - 0.5);

      const selectedQuestions = filteredQuestions.map((question) => {
        const shuffledOptions = [...question.options].sort(() => Math.random() - 0.5);
        return { ...question, shuffledOptions };
      });

    setSelectedQuestions(selectedQuestions.slice(0,10));
  }, [topic, difficulty]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleTopicChange = (event) => {
    const newTopic = event.target.value;
    setTopic(newTopic);
    setSelectedOption(null);
    setDifficulty(DEFAULT_DIFFICULTY);
    setCurrentQuestionIndex(null);
    setScore(0);
  };

  const handleDifficultyChange = (event) => {
    const newDifficulty = event.target.value;
    setDifficulty(newDifficulty);
    setSelectedOption(null);
    setCurrentQuestionIndex(null);
    setScore(0);
  };

  useEffect(() => {
    if (isTimerRunning) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isTimerRunning]);

  const handleStartClick = () => {
    setIsTimerRunning(true);
    setCurrentQuestionIndex(0);
    setScore(0);
    setShowQuizForm(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedAnswer = event.target.elements[`answer-${currentQuestionIndex}`].value;
    const currentQuestion = selectedQuestions[currentQuestionIndex];
    const isCorrect = selectedAnswer === currentQuestion.answer;

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    } else {
      if (showWrongAnswerPopup && selectedOption !== currentQuestion.answer) {
        setShowWrongAnswerPopup(true);
        return;
      }
    }

    setSelectedOption(null);
  
    const nextQuestionIndex = currentQuestionIndex + 1;
    if (nextQuestionIndex >= selectedQuestions.length) {
      setIsTimerRunning(false);
      setCurrentQuestionIndex(null);
    } else {
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  const handleClosePopup = () => {
    setShowWrongAnswerPopup(false);
  };

  const handlePlayAgainClick = () => {
    setTopic(DEFAULT_TOPIC);
    setDifficulty(DEFAULT_DIFFICULTY);
    setShowQuizForm(true);
    setCurrentQuestionIndex(null);
    setScore(0);
    setTimer(0);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
      return `${hours} Hour${hours > 1 ? 's' : ''}, ${minutes} Minute${minutes > 1 ? 's' : ''}, and ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} Minute${minutes > 1 ? 's' : ''} and ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
    } else if (remainingSeconds > 0) {
      return `${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
    } else {
      return '0 Seconds';
    }
  };

  const quizData = QuizData();

  const handleToggleSwitch = () => {
    setShowScoreTimer(!showScoreTimer);
  };

  const handleTogglePopupSwitch = () => {
    setShowWrongAnswerPopup(!showWrongAnswerPopup);
  };

  return (
    <>
    <div className="quiz-container">
      <QuizNavbar></QuizNavbar>
      {showQuizForm && (
        <QuizForm
          topic={topic}
          difficulty={quizData.difficulty}
          topicOptions={quizData.topicOptions}
          handleTopicChange={handleTopicChange}
          handleDifficultyChange={handleDifficultyChange}
          handleStartClick={handleStartClick}
        />
      )}

      <div className="quiz-column"></div>

      {!showQuizForm && currentQuestionIndex !== null && (
        <div className="quiz-question-container quiz-position">
            <p className="quiz-status">Question {currentQuestionIndex + 1} of {selectedQuestions.length}</p>

             <div className="slider-container">
      <div className="toggle-switch-wrapper">
        <label htmlFor="popup-switch" className={`slider-label ${showWrongAnswerPopup ? 'on' : 'off'}`} onClick={handleTogglePopupSwitch}>
          <span className="quiz-status">{showWrongAnswerPopup ? 'Show wrong answer pop up' : 'Hide wrong answer pop up'}</span>
        </label>
        <div className="toggle-switch-container">
          <Switch
            checked={showWrongAnswerPopup}
            onChange={handleTogglePopupSwitch}
            onColor="#2196f3"
            offColor="#ccc"
            onHandleColor="#fff"
            offHandleColor="#fff"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={40}
            className="toggle-switch"
          />
        </div>
      </div>

      <div className="quiz-footer">
        <div className="switch-align">
          {showScoreTimer && (
          <>
          <div className="quiz-footer">
            <div className="quiz-timer">
              <span className="quiz-timer-label">Timer: </span> {formatTime(timer)} <span className="hide-line">|</span> <span className="quiz-score-label">Score: </span> {score} / {selectedQuestions.length} 
            </div>
          </div>
        </>
          )}
        <Switch
           checked={showScoreTimer}
           onChange={handleToggleSwitch}
            onColor="#2196f3"
            offColor="#ccc"
            onHandleColor="#fff"
            offHandleColor="#fff"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            height={24}
            width={40}
            className="toggle-switch"
          />
</div>
</div>


            <br></br>
            <hr className="quiz-hr"></hr>
            <div className="question-container">
            <h2 className="quiz-question">{selectedQuestions[currentQuestionIndex].question}</h2>
            </div>
           
          </div>

          {showWrongAnswerPopup && selectedOption !== null && selectedOption !== selectedQuestions[currentQuestionIndex].answer && (
        <div className="wrong-answer-popup-wrapper">
          <div className="wrong-answer-popup">
            <div className="popup-content">
              <p className="wrong-answer-message">
                {selectedQuestions[currentQuestionIndex].wrongAnswerPopup}
              </p>
              <button className="popup-button" onClick={handleClosePopup}>
                Continue
              </button>
            </div>
          </div>
        </div>
      )}


<form className="quiz-answer-form" onSubmit={handleSubmit}>
        {selectedQuestions[currentQuestionIndex].shuffledOptions.map((option, index) => (
          <div key={index} className="quiz-option">
            <input
              type="radio"
              className="form-check-input"
              id={`option-${index}`}
              name={`answer-${currentQuestionIndex}`}
              value={option}
              required
              checked={selectedOption === option}
              onChange={handleOptionChange}
            />
            <label
              className={`form-check-label ${selectedOption === option ? "selected" : ""}`}
              htmlFor={`option-${index}`}
            >
              {option}
            </label>
          </div>
        ))}

<div className="quiz-button-position">
          <button type="submit" className="flashcards-button">
            Submit
          </button>
        </div>
    </form>
  </div>
)}

      {!showQuizForm && currentQuestionIndex === null && (
        <div className="quiz-results-container">
          <h2 className="quiz-results-header">Results</h2>
          <p className="quiz-results-text">
            You scored <strong>{score} / {selectedQuestions.length}</strong> correct answers within <strong>{formatTime(timer)}</strong> in the <strong>{quizData.topicOptions.find((option) => option.value === topic)?.label}</strong> quiz.
          </p>
          <button type="button" className="flashcards-button" onClick={handlePlayAgainClick}>
            Learn more
          </button>
        </div>
      )}
      <div className="new-button QB-about-page-subtitle inline-text bottom-button">
        <a className="chatbot-link white" href="https://mb3kald91rd.typeform.com/to/V7XGWgMF" target="_blank" rel="noreferrer">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <strong>Improvements / Feedback Form</strong>
      </a>
  </div>
    </div>
     <AdvocateFooter className="chatbot-footer-mobile" />
     </>
  );
};

export default Quiz;
