const FlashcardsData = () => {

  const topicOptions = [
    { value: 'codeOfConduct', label: 'Code of Conduct' },
    { value: 'CommunityCare', label: 'Community Care' },
    { value: 'ContentCreator', label: 'Content Creator' },
    { value: 'EventEvangelist', label: 'Event Evangelist' },
    { value: 'TechnicalTeacher', label: 'Technical Teacher' },
    { value: 'TextTranslator', label: 'Text Translator' },
  ];
  
  const difficultyOptions = [
    { value: 'easy', label: 'Easy' },
    { value: 'medium', label: 'Medium' },
    { value: 'hard', label: 'Hard' },
  ];
  
  const questions = [
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'What does the Code of Conduct aim to establish for participants in the Graph Advocates program?',
      answer: 'The Code of Conduct aims to establish expectations for the conduct of participants, ensuring a positive environment for the community.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'What is the consequence of violating the Code of Conduct during online or offline events?',
      answer: "Violating the Code of Conduct during events, whether online or offline, can lead to removal from the Graph Advocates' program."
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'How are Advocates expected to behave when creating content in line with the Code of Conduct?',
      answer: 'Advocates are expected to maintain high standards of integrity, avoiding plagiarism and properly citing original sources when creating content.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'Can Advocates discuss or endorse specific products or services without disclosure?',
      answer: 'Advocates should avoid representing a business or organization without disclosure of relevant affiliations, employment, or compensation, ensuring transparency.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'Where can Advocates report violations of the Code of Conduct?',
      answer: 'Violations can be reported to advocates@thegraph.foundation for confidential handling by the Advocate Committee.'
    },
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: "What is the primary purpose of The Graph Advocates' Code of Conduct?",
      answer: 'The Code of Conduct outlines expectations for the conduct of participants in the Advocates program to maintain a welcoming and productive environment.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: "What actions can result in removal from the Graph Advocates' program?",
      answer: "Any violation of the policies or guidelines in the Code of Conduct can lead to removal from the Graph Advocates' program."
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'What minimum contributions are Advocates expected to make in the program?',
      answer: 'Advocates are expected to contribute in areas like hosting events, creating content, translating text, providing community care, teaching technical topics, and welcoming new individuals.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'What is the importance of Advocates in The Graph ecosystem?',
      answer: 'Advocates play a critical role in shaping the future of web3 and are essential contributors to The Graph ecosystem.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'easy',
      question: 'What is the role of the Advocate Committee in handling Code of Conduct violations?',
      answer: 'The Advocate Committee investigates reported violations and ensures confidentiality, gathering information to determine if a violation has occurred.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'How does the Code of Conduct address the role of Advocates during disagreements or criticism?',
      answer: 'Advocates are expected to act professionally during disagreements, avoiding name-calling, hate speech, or any form of disruptive behavior.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'What is the significance of Advocates using preferred titles and appropriate tones?',
      answer: 'Using preferred titles and appropriate tones demonstrates professionalism, respect for privacy, and contributes to a positive community environment.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'How does the Code of Conduct address the issue of Advocates acting for personal gain?',
      answer: "Advocates are prohibited from acting for personal gain, including solicitation or marketing, to maintain the program's integrity."
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'What actions might be considered violations under the "Maintain Professionalism" section?',
      answer: 'Violations may include hate speech, discrimination, verbal harassment, or any behavior disruptive to the ecosystem.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'How can Advocates contribute beyond the minimum expectations, according to the Code of Conduct?',
      answer: 'Advocates are encouraged to contribute more than the minimum expectations, and the community can reward such efforts with grants for their contributions.'
    },
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'How does the Code of Conduct address conflicts of interest among Advocates?',
      answer: 'Advocates are expected to disclose any potential conflicts of interest that might compromise their ability to contribute objectively.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'What actions are prohibited under the "Act with Integrity" section of the Code of Conduct?',
      answer: 'Prohibited actions include misleading communications, sharing false information, intellectual property infringement, and misrepresentation.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'What does the Code of Conduct say about Advocates engaging in discussions related to the price of the GRT token?',
      answer: 'Advocates are not allowed to participate in any discussions or promotions related to the price of the GRT token.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'How can Advocates contribute to creating an inclusive and diverse environment within the Graph Advocates program?',
      answer: 'Advocates are encouraged to actively support diversity and inclusion initiatives, promote respectful communication, and be mindful of cultural differences.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'medium',
      question: 'What resources and support are available to Advocates who experience harassment or inappropriate behavior?',
      answer: 'Advocates can confidentially report incidents to advocates@thegraph.foundation, and the Advocate Committee is committed to providing support and taking appropriate actions.'
    },
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'Under what circumstances might an Advocate be temporarily suspended pending a Code of Conduct investigation?',
      answer: 'If a Code of Conduct violation is severe and immediate action is needed to protect the community, the Advocate may be temporarily suspended pending an investigation.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'How does the Advocate Committee ensure fair and unbiased Code of Conduct investigations?',
      answer: 'The Advocate Committee employs a rigorous and impartial investigative process, involving multiple members to prevent bias.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'What steps does the Graph Council take in handling Code of Conduct violations?',
      answer: 'The Graph Council, along with Advocates, determines if a violation has occurred, notifies the accused Advocate, and gathers information about the incident.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'How does the Code of Conduct address the balance between free speech and maintaining a respectful environment?',
      answer: 'The Code sets boundaries to ensure respectful communication while encouraging open dialogue, within the defined limits.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'What measures are in place to protect the privacy of individuals involved in reported Code of Conduct violations?',
      answer: 'The Advocate Committee ensures confidentiality in handling reported violations, prioritizing the privacy of all parties involved in the investigation process.'
    },
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'What role does constructive criticism play in the Code of Conduct, and where should it be shared?',
      answer: 'Constructive criticism is encouraged but should be shared in private channels to maintain a positive and collaborative community.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'How does the Code of Conduct address the potential misuse of property belonging to The Graph or others by Advocates?',
      answer: 'Advocates are prohibited from taking or damaging property and may face disciplinary action, including legal measures, for such violations.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'How does the Code of Conduct balance the need for resourcefulness with limitations?',
      answer: 'Advocates are expected to navigate situations resourcefully and seek assistance when needed, promoting collaboration within the community.'
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'What is the role of the Graph Council in removing Advocates from the program, and under what circumstances?',
      answer: "The Graph Council may immediately remove an Advocate for disruptive behavior not aligned with the Code of Conduct, without notice, for the ecosystem's well-being."
    },
    
    {
      topic: 'codeOfConduct',
      difficulty: 'hard',
      question: 'How does the Code of Conduct handle violations involving intellectual property and copyright infringement?',
      answer: 'Advocates are expected to respect intellectual property rights, and violations may result in removal, as The Graph may request the removal of infringing content.'
    },



      {
        topic: 'communityCare',
        difficulty: 'easy',
        question: "What is one effective way for Advocates to engage in community care within The Graph's program?",
        answer: 'Creating personal relationships with community members.',
      },
      {
        topic: 'communityCare',
        difficulty: 'easy',
        question: 'How can Advocates contribute to the growth of The Graph community through community care?',
        answer: 'Sharing educational resources and answering questions.',
      },
      {
        topic: 'communityCare',
        difficulty: 'easy',
        question: 'What is the minimum monthly engagement threshold for an Advocate involved in community care?',
        answer: '8 hours per month.',
      },
      {
        topic: 'communityCare',
        difficulty: 'easy',
        question: 'What counts as qualifying time for community care?',
        answer: 'Monitoring an endorsed Telegram chat without conversation.',
      },
      {
        topic: 'communityCare',
        difficulty: 'easy',
        question: 'How does the DAO verify reported hours for community care?',
        answer: 'Expertise in understanding effort and time from DAO members.',
      },
    
      // Medium
      {
        topic: 'communityCare',
        difficulty: 'medium',
        question: 'What counts as qualifying time for community care?',
        answer: 'Monitoring an endorsed Telegram chat without conversation.',
      },
      {
        topic: 'communityCare',
        difficulty: 'medium',
        question: 'How does the DAO verify reported hours for community care?',
        answer: 'Expertise in understanding effort and time from DAO members.',
      },
      {
        topic: 'communityCare',
        difficulty: 'medium',
        question: 'According to the material, why is understanding delegation and curation of token economics important for Advocates engaged in community care?',
        answer: 'To educate other community members and enhance their knowledge.',
      },
      {
        topic: 'communityCare',
        difficulty: 'medium',
        question: "Why is it important for Advocates to be familiar with The Graph's mission and values?",
        answer: 'To align their actions with the mission and values.',
      },
      {
        topic: 'communityCare',
        difficulty: 'medium',
        question: 'What are some key activities Advocates can engage in to contribute to community care?',
        answer: "Look out for the community, share content, answer community questions, or provide directions to additional resources, posted in The Graph's Telegram, Forum, Discord, or Reddit channels.",
      },
    
      // Hard
      {
        topic: 'communityCare',
        difficulty: 'hard',
        question: 'In the context of community care, why is there no 100% accurate way of verifying reported hours?',
        answer: 'Deep expertise in understanding effort and time in community care.',
      },
      {
        topic: 'communityCare',
        difficulty: 'hard',
        question: 'How does the DAO differentiate between active engagement and passive monitoring in the context of qualifying time for community care?',
        answer: 'Only actively participating in discussions qualifies as engagement.',
      },
      {
        topic: 'communityCare',
        difficulty: 'hard',
        question: 'What evidence does the DAO look for when verifying reported hours for community care?',
        answer: 'Frequency, difficulty, and quality of engagement across all social channels.',
      },
      {
        topic: 'communityCare',
        difficulty: 'hard',
        question: 'If an Advocate provides community care support on multiple platforms during a specified time window, what is the recommended approach for Proof of Contribution (PoC) submissions?',
        answer: 'Combine all contributions in one PoC submission.',
      },
      {
        topic: 'communityCare',
        difficulty: 'hard',
        question: 'What challenges does the DAO face in 100% accurate verification of reported hours for community care?',
        answer: "Dependence on community members' honesty.",
      },
  
    


    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q1',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q2',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q3',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q4',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q5',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q6',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q7',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q8',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q9',
      answer: 'A',
    },
    {
      topic: 'ContentCreator',
      difficulty: 'easy',
      question: 'Q10',
      answer: 'A',
    },

    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q1',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q2',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q3',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q4',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q5',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q6',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q7',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q8',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q9',
      answer: 'A',
    },
    {
      topic: 'EventEvangelist',
      difficulty: 'easy',
      question: 'Q10',
      answer: 'A',
    },

    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q1',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q2',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q3',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q4',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q5',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q6',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q7',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q8',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q9',
      answer: 'A',
    },
    {
      topic: 'TechnicalTeacher',
      difficulty: 'easy',
      question: 'Q10',
      answer: 'A',
    },

    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q1',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q2',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q3',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q4',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q5',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q6',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q7',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q8',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q9',
      answer: 'A',
    },
    {
      topic: 'TextTranslator',
      difficulty: 'easy',
      question: 'Q10',
      answer: 'A',
    }
  ];

  return {
    topicOptions,
    difficultyOptions,
    questions,
  };
};

export default FlashcardsData;
