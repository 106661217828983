import React from 'react';
import "../../App.css";
import PrivacyPolicyText from "./privacyPolicyText";

const PrivacyPolicyMain = () =>{
return (
	 <div className="privacy-policy">
       <PrivacyPolicyText />
    </div>
)}


export default PrivacyPolicyMain;