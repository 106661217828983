const FinalExamData = () => {

  const topicOptions = [
    { value: 'codeOfConduct', label: 'Code of Conduct' },
    { value: 'communityCare', label: 'Community Care' },
    { value: 'ContentCreator', label: 'Content Creator' },
    { value: 'EventEvangelist', label: 'Event Evangelist' },
    { value: 'TechnicalTeacher', label: 'Technical Teacher' },
    { value: 'TextTranslator', label: 'Text Translator' },
  ];

  const questions = [     {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'What does the Code of Conduct aim to establish for participants in the Graph Advocates program?',
    options: [
      'The Code of Conduct aims to establish expectations for the conduct of participants, ensuring a positive environment for the community.',
      'The Code of Conduct promotes aggressive behavior and competition among community members.',
      'The Code of Conduct encourages members to violate the guidelines to test their limits.',
      'The Code of Conduct limits the participation of Advocates in community activities.'
    ],
    answer: 'The Code of Conduct aims to establish expectations for the conduct of participants, ensuring a positive environment for the community.',
  },

  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'What is the consequence of violating the Code of Conduct during online or offline events?',
    options: [
      "Violating the Code of Conduct during events, whether online or offline, can lead to removal from the Graph Advocates' program.",
      'Violating the Code of Conduct has no consequences during online or offline events.',
      'Violating the Code of Conduct results in a warning but does not lead to removal.',
      'Violating the Code of Conduct leads to a temporary suspension but not removal.'
    ],
    answer: "Violating the Code of Conduct during events, whether online or offline, can lead to removal from the Graph Advocates' program.",
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'How are Advocates expected to behave when creating content in line with the Code of Conduct?',
    options: [
      'Advocates are expected to maintain high standards of integrity, avoiding plagiarism and properly citing original sources when creating content.',
      'Advocates are encouraged to use aggressive language and push boundaries when creating content.',
      'Advocates can freely endorse specific products or services without disclosure when creating content.',
      'Advocates are not required to cite sources when creating content.'
    ],
    answer: 'Advocates are expected to maintain high standards of integrity, avoiding plagiarism and properly citing original sources when creating content.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'Can Advocates discuss or endorse specific products or services without disclosure?',
    options: [
      'Advocates should avoid representing a business or organization without disclosure of relevant affiliations, employment, or compensation, ensuring transparency.',
      'Advocates are encouraged to discuss and endorse products without any disclosure requirements.',
      'Advocates can freely endorse products or services without considering disclosure.',
      'Advocates are not required to disclose affiliations when discussing or endorsing products.'
    ],
    answer: 'Advocates should avoid representing a business or organization without disclosure of relevant affiliations, employment, or compensation, ensuring transparency.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'Where can Advocates report violations of the Code of Conduct?',
    options: [
      'Violations can be reported to advocates@thegraph.foundation for confidential handling by the Advocate Committee.',
      'Advocates should report violations on public forums for transparency.',
      'Reporting violations is not encouraged in the Code of Conduct.',
      'Violations should be reported directly to The Graph Council.'
    ],
    answer: 'Violations can be reported to advocates@thegraph.foundation for confidential handling by the Advocate Committee.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: "What is the primary purpose of The Graph Advocates' Code of Conduct?",
    options: [
      'The Code of Conduct outlines expectations for the conduct of participants in the Advocates program to maintain a welcoming and productive environment.',
      'The primary purpose is to restrict the activities of Advocates within the program.',
      'The Code of Conduct primarily focuses on punitive measures for Advocates.',
      'The main goal is to limit the freedom of expression of Advocates.'
    ],
    answer: 'The Code of Conduct outlines expectations for the conduct of participants in the Advocates program to maintain a welcoming and productive environment.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: "What is the primary purpose of The Graph Advocates' Code of Conduct?",
    options: [
      'The Code of Conduct outlines expectations for the conduct of participants in the Advocates program to maintain a welcoming and productive environment.',
      'The primary purpose is to restrict the activities of Advocates within the program.',
      'The Code of Conduct primarily focuses on punitive measures for Advocates.',
      'The main goal is to limit the freedom of expression of Advocates.'
    ],
    answer: 'The Code of Conduct outlines expectations for the conduct of participants in the Advocates program to maintain a welcoming and productive environment.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: "What actions can result in removal from the Graph Advocates' program?",
    options: [
      "Any violation of the policies or guidelines in the Code of Conduct can lead to removal from the Graph Advocates' program.",
      'Advocates can only be removed for extreme violations, regardless of the Code of Conduct.',
      'Removal is only considered for Advocates with a long history of violations.',
      'Advocates are never removed, regardless of their actions.'
    ],
    answer: "Any violation of the policies or guidelines in the Code of Conduct can lead to removal from the Graph Advocates' program.",
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'What minimum contributions are Advocates expected to make in the program?',
    options: [
      'Advocates are expected to contribute in areas like hosting events, creating content, translating text, providing community care, teaching technical topics, and welcoming new individuals.',
      'There are no minimum contribution expectations for Advocates.',
      'Advocates are only expected to attend program-sponsored events.',
      'Contributions from Advocates are optional and not tracked.'
    ],
    answer: 'Advocates are expected to contribute in areas like hosting events, creating content, translating text, providing community care, teaching technical topics, and welcoming new individuals.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'What is the importance of Advocates in The Graph ecosystem?',
    options: [
      'Advocates play a critical role in shaping the future of web3 and are essential contributors to The Graph ecosystem.',
      'Advocates have a minor impact on the ecosystem and its development.',
      'The Graph ecosystem does not rely on contributions from Advocates.',
      'The role of Advocates is primarily administrative.'
    ],
    answer: 'Advocates play a critical role in shaping the future of web3 and are essential contributors to The Graph ecosystem.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'easy',
    question: 'What is the role of the Advocate Committee in handling Code of Conduct violations?',
    options: [
      'The Advocate Committee investigates reported violations and ensures confidentiality, gathering information to determine if a violation has occurred.',
      'The Advocate Committee has no role in handling Code of Conduct violations.',
      'The Advocate Committee only provides suggestions for handling violations.',
      'Handling Code of Conduct violations is the sole responsibility of The Graph Council.'
    ],
    answer: 'The Advocate Committee investigates reported violations and ensures confidentiality, gathering information to determine if a violation has occurred.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'How does the Code of Conduct address the role of Advocates during disagreements or criticism?',
    options: [
      'Advocates are expected to act professionally during disagreements, avoiding name-calling, hate speech, or any form of disruptive behavior.',
      'The Code of Conduct encourages Advocates to respond aggressively to criticism.',
      'Advocates are allowed to engage in personal attacks during disagreements.',
      "The Code of Conduct does not provide guidance on Advocates' behavior during disagreements."
    ],
    answer: 'Advocates are expected to act professionally during disagreements, avoiding name-calling, hate speech, or any form of disruptive behavior.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'What is the significance of Advocates using preferred titles and appropriate tones?',
    options: [
      'Using preferred titles and appropriate tones demonstrates professionalism, respect for privacy, and contributes to a positive community environment.',
      'Advocates can use any titles and tones without considering their impact.',
      'The use of titles and tones is irrelevant to the Code of Conduct.',
      'Advocates are encouraged to use provocative tones to attract attention.'
    ],
    answer: 'Using preferred titles and appropriate tones demonstrates professionalism, respect for privacy, and contributes to a positive community environment.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'How does the Code of Conduct address the issue of Advocates acting for personal gain?',
    options: [
      "Advocates are prohibited from acting for personal gain, including solicitation or marketing, to maintain the program's integrity.",
      'The Code of Conduct encourages Advocates to prioritize personal gain over community well-being.',
      'Advocates are allowed to use their position for personal gain as long as it does not violate other guidelines.',
      'The Code of Conduct is silent on the issue of Advocates acting for personal gain.'
    ],
    answer: "Advocates are prohibited from acting for personal gain, including solicitation or marketing, to maintain the program's integrity.",
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'What actions might be considered violations under the "Maintain Professionalism" section?',
    options: [
      'Violations may include hate speech, discrimination, verbal harassment, or any behavior disruptive to the ecosystem.',
      'Maintaining professionalism allows Advocates to engage in any behavior without consequences.',
      'Advocates are only expected to maintain professionalism during public events.',
      'The Code of Conduct does not define specific violations under the "Maintain Professionalism" section.'
    ],
    answer: 'Violations may include hate speech, discrimination, verbal harassment, or any behavior disruptive to the ecosystem.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'How can Advocates contribute beyond the minimum expectations, according to the Code of Conduct?',
    options: [
      'Advocates are encouraged to contribute more than the minimum expectations, and the community can reward such efforts with grants for their contributions.',
      'Contributing beyond the minimum expectations is discouraged by the Code of Conduct.',
      'Advocates are required to contribute only the minimum expectations outlined in the program guidelines.',
      'The Code of Conduct does not provide guidance on contributing beyond minimum expectations.'
    ],
    answer: 'Advocates are encouraged to contribute more than the minimum expectations, and the community can reward such efforts with grants for their contributions.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'How does the Code of Conduct address conflicts of interest among Advocates?',
    options: [
      'Advocates are expected to disclose any potential conflicts of interest that might compromise their ability to contribute objectively.',
      'The Code of Conduct ignores conflicts of interest among Advocates.',
      'Advocates are encouraged to use conflicts of interest to their advantage.',
      'The Code of Conduct prohibits any disclosure of conflicts of interest.'
    ],
    answer: 'Advocates are expected to disclose any potential conflicts of interest that might compromise their ability to contribute objectively.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'What actions are prohibited under the "Act with Integrity" section of the Code of Conduct?',
    options: [
      'Prohibited actions include misleading communications, sharing false information, intellectual property infringement, and misrepresentation.',
      'Acting with integrity allows Advocates to engage in any actions without consequences.',
      'Advocates are only expected to act with integrity during public events.',
      'The Code of Conduct does not define specific prohibitions under the "Act with Integrity" section.'
    ],
    answer: 'Prohibited actions include misleading communications, sharing false information, intellectual property infringement, and misrepresentation.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'What does the Code of Conduct say about Advocates engaging in discussions related to the price of the GRT token?',
    options: [
      'Advocates are not allowed to participate in any discussions or promotions related to the price of the GRT token.',
      'Advocates are encouraged to manipulate discussions related to the GRT token price for personal gain.',
      'There are no restrictions on Advocates discussing or promoting the price of the GRT token.',
      'The Code of Conduct does not address discussions related to the GRT token price.'
    ],
    answer: 'Advocates are not allowed to participate in any discussions or promotions related to the price of the GRT token.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'How can Advocates contribute to creating an inclusive and diverse environment within the Graph Advocates program?',
    options: [
      'Advocates are encouraged to actively support diversity and inclusion initiatives, promote respectful communication, and be mindful of cultural differences.',
      'Contributing to diversity and inclusion initiatives is optional, and Advocates are not expected to promote respectful communication.',
      'Advocates are discouraged from actively supporting diversity and inclusion initiatives.',
      'The Code of Conduct does not provide guidance on creating an inclusive and diverse environment.'
    ],
    answer: 'Advocates are encouraged to actively support diversity and inclusion initiatives, promote respectful communication, and be mindful of cultural differences.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'medium',
    question: 'What resources and support are available to Advocates who experience harassment or inappropriate behavior?',
    options: [
      'Advocates can confidentially report incidents to advocates@thegraph.foundation, and the Advocate Committee is committed to providing support and taking appropriate actions.',
      'There are no resources or support available to Advocates who experience harassment or inappropriate behavior.',
      'Advocates are required to handle incidents of harassment or inappropriate behavior independently.',
      'The Code of Conduct does not specify any actions for Advocates facing harassment or inappropriate behavior.'
    ],
    answer: 'Advocates can confidentially report incidents to advocates@thegraph.foundation, and the Advocate Committee is committed to providing support and taking appropriate actions.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'Under what circumstances might an Advocate be temporarily suspended pending a Code of Conduct investigation?',
    options: [
      'If a Code of Conduct violation is severe and immediate action is needed to protect the community, the Advocate may be temporarily suspended pending an investigation.',
      'Advocates are never temporarily suspended, regardless of the severity of the Code of Conduct violation.',
      'Temporary suspension is applied to any Advocate facing a Code of Conduct investigation, regardless of the circumstances.',
      'The Code of Conduct does not specify any circumstances for temporary suspension.'
    ],
    answer: 'If a Code of Conduct violation is severe and immediate action is needed to protect the community, the Advocate may be temporarily suspended pending an investigation.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'How does the Advocate Committee ensure fair and unbiased Code of Conduct investigations?',
    options: [
      'The Advocate Committee employs a rigorous and impartial investigative process, involving multiple members to prevent bias.',
      'Code of Conduct investigations are inherently biased, and the Advocate Committee does not take steps to ensure fairness.',
      'Advocates are responsible for ensuring fair and unbiased investigations, not the Advocate Committee.',
      'The Code of Conduct does not address the fairness of investigations conducted by the Advocate Committee.'
    ],
    answer: 'The Advocate Committee employs a rigorous and impartial investigative process, involving multiple members to prevent bias.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'What steps does the Graph Council take in handling Code of Conduct violations?',
    options: [
      'The Graph Council, along with Advocates, determines if a violation has occurred, notifies the accused Advocate, and gathers information about the incident.',
      'The Graph Council has no involvement in handling Code of Conduct violations.',
      'Code of Conduct violations are handled solely by the Advocate Committee, without the participation of the Graph Council.',
      'The Code of Conduct does not specify any steps for the Graph Council in handling violations.'
    ],
    answer: 'The Graph Council, along with Advocates, determines if a violation has occurred, notifies the accused Advocate, and gathers information about the incident.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'How does the Code of Conduct address the balance between free speech and maintaining a respectful environment?',
    options: [
      'The Code sets boundaries to ensure respectful communication while encouraging open dialogue, within the defined limits.',
      'Free speech is prioritized, and there are no restrictions on communication in the Code of Conduct.',
      'The Code of Conduct does not address the balance between free speech and maintaining a respectful environment.',
      'Advocates are prohibited from engaging in any form of communication to maintain a controlled environment.'
    ],
    answer: 'The Code sets boundaries to ensure respectful communication while encouraging open dialogue, within the defined limits.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'What measures are in place to protect the privacy of individuals involved in reported Code of Conduct violations?',
    options: [
      'The Advocate Committee ensures confidentiality in handling reported violations, prioritizing the privacy of all parties involved in the investigation process.',
      'The Code of Conduct does not address the privacy concerns of individuals involved in reported violations.',
      'Advocates are responsible for protecting their own privacy in Code of Conduct investigations.',
      'The Graph Council handles privacy concerns, while the Advocate Committee focuses on the investigation process.'
    ],
    answer: 'The Advocate Committee ensures confidentiality in handling reported violations, prioritizing the privacy of all parties involved in the investigation process.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'What role does constructive criticism play in the Code of Conduct, and where should it be shared?',
    options: [
      'Constructive criticism is encouraged but should be shared in private channels to maintain a positive and collaborative community.',
      'Constructive criticism is not allowed as per the Code of Conduct guidelines.',
      'Advocates should only provide positive feedback and refrain from constructive criticism.',
      'The Code of Conduct does not address the role or sharing of constructive criticism.'
    ],
    answer: 'Constructive criticism is encouraged but should be shared in private channels to maintain a positive and collaborative community.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'How does the Code of Conduct address the potential misuse of property belonging to The Graph or others by Advocates?',
    options: [
      'Advocates are prohibited from taking or damaging property and may face disciplinary action, including legal measures, for such violations.',
      'The Code of Conduct does not address the issue of property misuse by Advocates.',
      'Advocates have the freedom to use property as they see fit, without any restrictions.',
      'Misuse of property is allowed if it benefits the Advocate individually.'
    ],
    answer: 'Advocates are prohibited from taking or damaging property and may face disciplinary action, including legal measures, for such violations.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'How does the Code of Conduct balance the need for resourcefulness with limitations?',
    options: [
      'Advocates are expected to navigate situations resourcefully and seek assistance when needed, promoting collaboration within the community.',
      'Resourcefulness is discouraged in the Code of Conduct, and Advocates should strictly adhere to limitations.',
      'There are no guidelines on resourcefulness or limitations in the Code of Conduct.',
      'Advocates should always rely on others and not demonstrate resourcefulness.'
    ],
    answer: 'Advocates are expected to navigate situations resourcefully and seek assistance when needed, promoting collaboration within the community.',
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'What is the role of the Graph Council in removing Advocates from the program, and under what circumstances?',
    options: [
      "The Graph Council may immediately remove an Advocate for disruptive behavior not aligned with the Code of Conduct, without notice, for the ecosystem's well-being.",
      'The Graph Council has no authority in removing Advocates; removal decisions are solely made by the Advocate Committee.',
      'The Graph Council can only recommend removal, and final decisions rest with the broader community.',
      'Advocates can only be removed by a majority vote from the entire Graph Advocates program.'
    ],
    answer: "The Graph Council may immediately remove an Advocate for disruptive behavior not aligned with the Code of Conduct, without notice, for the ecosystem's well-being.",
  },
  {
    topic: 'codeOfConduct',
    difficulty: 'hard',
    question: 'How does the Code of Conduct handle violations involving intellectual property and copyright infringement?',
    options: [
      'Advocates are expected to respect intellectual property rights, and violations may result in removal, as The Graph may request the removal of infringing content.',
      'The Code of Conduct does not address intellectual property or copyright infringement issues.',
      'Violations related to intellectual property are only addressed through warnings, with no removal consequences.',
      'Advocates are free to use any intellectual property without restrictions, according to the Code of Conduct.'
    ],
    answer: 'Advocates are expected to respect intellectual property rights, and violations may result in removal, as The Graph may request the removal of infringing content.',
  },







    {
      topic: 'communityCare',
      difficulty: 'easy',
      question: "What is one effective way for Advocates to engage in community care within The Graph's program?",
      options: [
        'Ignoring community questions.',
        'Creating personal relationships with community members.',
        'Enforcing strict policies without explanation.',
        'Limiting interactions to specific platforms only.'
      ],
      answer: 'Creating personal relationships with community members.'
    },
    {
      topic: 'communityCare',
      difficulty: 'easy',
      question: 'How can Advocates contribute to the growth of The Graph community through community care?',
      options: [
        'Avoiding regional Telegram channels.',
        'Enforcing established policies only.',
        'Sharing educational resources and answering questions.',
        'Limiting interactions to the Forum channel.'
      ],
      answer: 'Sharing educational resources and answering questions.'
    },
    {
      topic: 'communityCare',
      difficulty: 'easy',
      question: 'What is the minimum monthly engagement threshold for an Advocate involved in community care?',
      options: [
        '5 hours per month.',
        '15 hours per month.',
        '10 hours per month.',
        '8 hours per month.'
      ],
      answer: '8 hours per month.'
    },
    {
      topic: 'communityCare',
      difficulty: 'easy',
      question: 'What counts as qualifying time for community care?',
      options: [
        'Monitoring an endorsed Telegram chat without conversation.',
        'Engaging in irrelevant discussions for hours.',
        'Ignoring community platforms during the selected time window.',
        'Only focusing on official channels without active engagement.'
      ],
      answer: 'Monitoring an endorsed Telegram chat without conversation.'
    },
    {
      topic: 'communityCare',
      difficulty: 'easy',
      question: 'How does the DAO verify reported hours for community care?',
      options: [
        '100% accurate verification methods.',
        'Expertise in understanding effort and time from DAO members.',
        'No verification process for reported hours.',
        'Automated tools for real-time tracking.'
      ],
      answer: 'Expertise in understanding effort and time from DAO members.'
    },
  
{
  topic: 'communityCare',
  difficulty: 'medium',
  question: 'What counts as qualifying time for community care?',
  options: [
    'Monitoring an endorsed Telegram chat without conversation.',
    'Engaging in irrelevant discussions for hours.',
    'Ignoring community platforms during the selected time window.',
    'Only focusing on official channels without active engagement.'
  ],
  answer: 'Monitoring an endorsed Telegram chat without conversation.'
},
{
  topic: 'communityCare',
  difficulty: 'medium',
  question: 'How does the DAO verify reported hours for community care?',
  options: [
    '100% accurate verification methods.',
    'Expertise in understanding effort and time from DAO members.',
    'No verification process for reported hours.',
    'Automated tools for real-time tracking.'
  ],
  answer: 'Expertise in understanding effort and time from DAO members.'
},
{
  topic: 'communityCare',
  difficulty: 'medium',
  question: 'According to the material, why is understanding delegation and curation of token economics important for Advocates engaged in community care?',
  options: [
    'To create unnecessary complexity in discussions.',
    'To limit community members from accessing valuable information.',
    'To educate other community members and enhance their knowledge.',
    'To discourage community growth.'
  ],
  answer: 'To educate other community members and enhance their knowledge.'
},
{
  topic: 'communityCare',
  difficulty: 'medium',
  question: "Why is it important for Advocates to be familiar with The Graph's mission and values?",
  options: [
    'To undermine the mission and values.',
    'To create confusion and chaos.',
    'To align their actions with the mission and values.',
    'To disregard the importance of mission and values.'
  ],
  answer: 'To align their actions with the mission and values.'
},
{
  topic: 'communityCare',
  difficulty: 'medium',
  question: 'How can Advocates effectively contribute to community care?',
  options: [
    'Avoiding all community platforms for privacy.',
    'Engaging in irrelevant discussions for hours.',
    'Ignoring community questions and concerns.',
    'Look out for the community, share content, answer community questions, or provide directions to additional resources, posted in The Graph\'s Telegram, Forum, Discord, or Reddit channels.'
  ],
  answer: 'Look out for the community, share content, answer community questions, or provide directions to additional resources, posted in The Graph\'s Telegram, Forum, Discord, or Reddit channels.'
},

{
  topic: 'communityCare',
  difficulty: 'hard',
  question: 'In the context of community care, why is there no 100% accurate way of verifying reported hours?',
  options: [
    'Limited expertise in understanding community care efforts.',
    'Lack of tools for automated tracking.',
    'Deep expertise in understanding effort and time in community care.',
    'Automated tools providing real-time tracking.'
  ],
  answer: 'Deep expertise in understanding effort and time in community care.'
},
{
  topic: 'communityCare',
  difficulty: 'hard',
  question: 'How does the DAO differentiate between active engagement and passive monitoring in the context of qualifying time for community care?',
  options: [
    'Monitoring endorsed Telegram chats qualifies as active engagement.',
    'Only actively participating in discussions qualifies as engagement.',
    'Both active engagement and passive monitoring qualify as community care.',
    'Ignoring conversations during monitoring disqualifies the time for community care.'
  ],
  answer: 'Only actively participating in discussions qualifies as engagement.'
},
{
  topic: 'communityCare',
  difficulty: 'hard',
  question: 'What evidence does the DAO look for when verifying reported hours for community care?',
  options: [
    'Real-time tracking through automated tools.',
    'Frequency, difficulty, and quality of engagement across all social channels.',
    "Community members' testimonials.",
    'Verifiable timestamps on every community care interaction.'
  ],
  answer: 'Frequency, difficulty, and quality of engagement across all social channels.'
},
{
  topic: 'communityCare',
  difficulty: 'hard',
  question: 'If an Advocate provides community care support on multiple platforms during a specified time window, what is the recommended approach for Proof of Contribution (PoC) submissions?',
  options: [
    'Submit one PoC for each platform.',
    'Combine all contributions in one PoC submission.',
    'Submit a PoC for each day of engagement.',
    'Ignore the PoC submission for multiple platform engagements.'
  ],
  answer: 'Combine all contributions in one PoC submission.'
},
{
  topic: 'communityCare',
  difficulty: 'hard',
  question: 'What challenges does the DAO face in 100% accurate verification of reported hours for community care?',
  options: [
    'Limited expertise in understanding community care efforts.',
    'Lack of tools for automated tracking.',
    "Dependence on community members' honesty.",
    'Deep expertise in understanding effort and time in community care.'
  ],
  answer: "Dependence on community members' honesty."
},







   {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q1',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
   {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q2',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
   {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q3',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q4',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
   {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q5',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q6',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q7',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q8',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q9',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'ContentCreator',
    difficulty: 'easy',
    question: 'Q10',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },

  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q1',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q2',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q3',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q4',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q5',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q6',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q7',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q8',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q9',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'EventEvangelist',
    difficulty: 'easy',
    question: 'Q10',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },

  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q1',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q2',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q3',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q4',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q5',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q6',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q7',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q8',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q9',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'TechnicalTeacher',
    difficulty: 'easy',
    question: 'Q10',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },

  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q1',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q2',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q3',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q4',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q5',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q6',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A4',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q7',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A1',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q8',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A3',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q9',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  },
  {
    topic: 'TextTranslator',
    difficulty: 'easy',
    question: 'Q10',
    options: ['A1', 'A2', 'A3', 'A4'],
    answer: 'A2',
  }
];

  return {
    topicOptions,
    questions,
  };
};

export {FinalExamData};
