import React, { useState, useEffect, useRef } from 'react';
import '../../App.css';
import FinalExamForm from './FinalExamForm';
import FinalExamNavbar from '../navbars/FinalExamNavbar';
import { FinalExamData } from './FinalExamData';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import AdvocateFooter from '../footer/AdvocateFooter';

const CertificateContent = ({ name, score, selectedQuestions, timer, topic, role, formatTime, finalExamData }) => (
  <div className="certificate-content">
    <p className="quiz-results-text">
      Congratulations {name}, you passed!
    </p>
    <p className="quiz-results-text">
      You scored <strong>{score} / {selectedQuestions.length}</strong> correct answers within <strong>{formatTime(timer)}</strong> in the <strong>{finalExamData.topicOptions.find((option) => option.value === topic)?.label}</strong> exam.
    </p>
    <p className="quiz-results-text">
      You wish to pursue the <strong><em>{role}</em></strong> role within the Graph Advocates.   
    </p>
  </div>
);

const FinalExam = () => {
const DEFAULT_TOPIC = '';
const DEFAULT_DIFFICULTY = '';

const [name, setName] = useState('');
const [topic, setTopic] = useState(DEFAULT_TOPIC);
const [difficulty, setDifficulty] = useState(DEFAULT_DIFFICULTY);
const [score, setScore] = useState(0);
const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
const [showFinalExamForm, setShowFinalExamForm] = useState(true);
const [timer, setTimer] = useState(0);
const [isTimerRunning, setIsTimerRunning] = useState(false);
const [selectedOption, setSelectedOption] = useState(null);
const [role, setRole] = useState('');
const [selectedQuestions, setSelectedQuestions] = useState([]);

useEffect(() => {
  const filteredQuestions = finalExamData.questions
    .filter(
      (question) =>
        question.topic === topic && question.difficulty === difficulty
    )
    .sort(() => Math.random() - 0.5); 

  const selectedQuestions = filteredQuestions.map((question) => {
    const shuffledOptions = [...question.options].sort(() => Math.random() - 0.5);
    return { ...question, shuffledOptions };
  });

  setSelectedQuestions(selectedQuestions.slice(0, 10));
}, [topic, difficulty]);

const handleOptionChange = (event) => {
  setSelectedOption(event.target.value);
};

const handleName = (event) => {
const newName = event.target.value;
setName(newName);
};

const handleTopicChange = (event) => {
  const newTopic = event.target.value;
  setTopic(newTopic);
  setSelectedOption(null);
  setDifficulty(DEFAULT_DIFFICULTY);
  setCurrentQuestionIndex(null);
  setScore(0);
};

const handleDifficultyChange = (event) => {
  const newDifficulty = event.target.value;
  setDifficulty(newDifficulty);
  setSelectedOption(null);
  setCurrentQuestionIndex(null);
  setScore(0);
};


const handleRoleChange = (event) => {
  const newRole = event.target.value;
  setRole(newRole);
};


useEffect(() => {
if (isTimerRunning) {
const interval = setInterval(() => {
setTimer((prevTimer) => prevTimer + 1);
}, 1000);
return () => clearInterval(interval);
}
}, [isTimerRunning]);

const handleStartClick = () => {
setIsTimerRunning(true);
setCurrentQuestionIndex(0);
setScore(0);
setShowFinalExamForm(false);
};

const handleSubmit = (event) => {
  event.preventDefault();

  const selectedAnswer = event.target.elements["answer"].value;
  const currentQuestion = selectedQuestions[currentQuestionIndex];

  const isCorrect = selectedAnswer === currentQuestion.answer;

  if (isCorrect) {
    setScore((prevScore) => prevScore + 1);
  }

  const nextQuestionIndex = currentQuestionIndex + 1;

  if (nextQuestionIndex >= selectedQuestions.length) {
    setIsTimerRunning(false);
    setCurrentQuestionIndex(null);
  } else {
    setCurrentQuestionIndex(nextQuestionIndex);
  }
  setSelectedOption(null);
};


const handlePlayAgainClick = () => {
setName("");
setTopic(DEFAULT_TOPIC);
setDifficulty(DEFAULT_DIFFICULTY);
setShowFinalExamForm(true);
setCurrentQuestionIndex(null);
setScore(0);
setTimer(0);
};

const certificateRef = useRef();

const handleDownloadCertificate = () => {
  const certificateElement = certificateRef.current;

  const container = document.createElement('div');
  container.style.backgroundColor = 'rgb(37,14,100)';
  container.style.color = 'red';
  container.style.font = '30px Arial';
  container.style.padding = '4rem';

  container.appendChild(certificateElement.cloneNode(true));

  document.body.appendChild(container);

  html2canvas(container, { scrollY: -window.scrollY }).then(canvas => {
    const dataUrl = canvas.toDataURL('image/png');
    saveAs(dataUrl, 'AdvocateReady.png');

    document.body.removeChild(container);
  }).catch(error => {
    console.error("Error during screenshot capture:", error);

    document.body.removeChild(container);
  });
};


const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  
  if (hours > 0) {
    return `${hours} Hour${hours > 2 ? 's' : ''}, ${minutes} Minute${minutes > 1 ? 's' : ''}, and ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
  } else if (hours === 1) {
    return `${hours} Hour, ${minutes} Minute${minutes > 1 ? 's' : ''}, and ${remainingSeconds < 10 ? '0' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} Minute${minutes > 1 ? 's' : ''} and ${remainingSeconds < 10 ? '' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
  } else if (remainingSeconds > 0) {
    return `${remainingSeconds < 10 ? '' : ''}${remainingSeconds} Second${remainingSeconds > 1 ? 's' : ''}`;
  } else {
    return '0 Seconds';
  }
};

const finalExamData = FinalExamData();

const filteredQuestions = finalExamData.questions.filter(
  (question) =>
    question.topic === topic && question.difficulty === difficulty
);

return (
  <>
<div className="final-exam quiz-container">
<FinalExamNavbar></FinalExamNavbar>
  {showFinalExamForm && (
     <FinalExamForm
     topic={topic}
     difficulty={finalExamData.difficulty}
     topicOptions={finalExamData.topicOptions}
     handleName={handleName}
     handleRoleChange={handleRoleChange}
     handleTopicChange={handleTopicChange}
     handleDifficultyChange={handleDifficultyChange}
     handleStartClick={handleStartClick}
   />
  )}

  {!showFinalExamForm && currentQuestionIndex !== null && (
    <div className="quiz-question-container">
      <div className="quiz-header">
        <div className="quiz-progress-bar">
          <div className="quiz-progress-bar-inner" style={{ width: `${(currentQuestionIndex + 1) / selectedQuestions.length * 100}%` }} />
        </div>
        <br></br>
        <p className="quiz-status">Question {currentQuestionIndex + 1} of {selectedQuestions.length}</p>
        <br></br>
        <h2 className="final-exam-question">{selectedQuestions[currentQuestionIndex].question}</h2>
      </div>

      <form className="final-exam-answer-form" onSubmit={handleSubmit}>
      {selectedQuestions[currentQuestionIndex].shuffledOptions.map(
    (option, index) => (
        <div key={index} className="quiz-option">
          <input
            type="radio"
            className="form-check-input"
            id={`option-${index}`}
            name="answer"
            value={option}
            required
            checked={selectedOption === option}
            onChange={handleOptionChange}
          />
          <label
            className={`form-check-label ${selectedOption === option ? "selected" : ""}`}
            htmlFor={`option-${index}`}
          >
            {option}
          </label>
        </div>
      ))}
     <div className="quiz-button-position">
            <button type="submit" className="flashcards-button">
              Submit
            </button>
            </div>
    </form>
    </div>
  )}

{!showFinalExamForm && currentQuestionIndex === null && (
  <div className="quiz-results-container">
    {score >= 8 ? (
      <div ref={certificateRef} className="certificate-container quiz-results-container">
        <CertificateContent
          name={name}
          score={score}
          selectedQuestions={selectedQuestions}
          timer={timer}
          topic={topic}
          role={role}
          formatTime={formatTime}
          finalExamData={finalExamData}
        />
        <button type="button" className="flashcards-button" onClick={handleDownloadCertificate}>
          Download Certificate
        </button>
      </div>
    ) : (
      <div className="quiz-results-container quiz-results-cert">
        <p className="quiz-results-text">
          Sorry {name}, you did not pass this time.
        </p>
    <p className="quiz-results-text">
      You scored <strong>{score} / {selectedQuestions.length}</strong> correct answers within <strong>{formatTime(timer)}</strong> in the <strong>{finalExamData.topicOptions.find((option) => option.value === topic)?.label}</strong> exam.
    </p>
    <p className="quiz-results-text">
      Visit the Flashcards, Documentation Finder and practice Quiz before retaking the exam to improve your score.
    </p>
  </div>
)}
    
      <button type="button" className="flashcards-button" onClick={handlePlayAgainClick}>
        Play Again
      </button>
    </div>
  )}
     <br />
      <div className="new-button QB-about-page-subtitle inline-text bottom-button">
        <a className="chatbot-link white" href="https://mb3kald91rd.typeform.com/to/V7XGWgMF" target="_blank" rel="noreferrer">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <strong>Improvements / Feedback Form</strong>
      </a>
  </div>
</div>
 <AdvocateFooter className="chatbot-footer-mobile" />
 </>
);
};

export default FinalExam;






