import React from 'react';
import "../../App.css";
import CookiesPolicyText from "./cookiesPolicyText";

const CookiesPolicyMain = () =>{
    
return (
	 <div className="privacy-policy">
       <CookiesPolicyText />
    </div>
)}


export default CookiesPolicyMain;