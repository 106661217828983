import { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from 'react-number-format';

function NewTotalTokensSignalledDirectly() {
  const [totalTokensSignalledDirectly, setTotalTokensSignalledDirectly] = useState(0);

  useEffect(() => {
    const fetchTotalTokensSignalledDirectly = async () => {
      try {
        const response = await axios.post(
          "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-mainnet",
          {
            query: 
              `{
                graphNetworks(first: 1, orderBy: epochCount, orderDirection: desc) {
                  curatorCount
                  activeCuratorCount
                  totalTokensAllocated
                  
                  delegatorCount
                  activeDelegatorCount
                  delegationCount
                  activeDelegationCount
                  totalDelegatedTokens
                  
                  indexerCount
                  stakedIndexersCount
                  totalTokensStaked
                
                  
                  totalTokensSignalled
                  
                  subgraphCount
                  subgraphDeploymentCount
                  activeSubgraphCount
                  
                  
                  
                  totalGRTBurned
                  totalGRTMinted
                 
                  totalQueryFees
                  totalSupply
                  
                  assetHolders
                  
                  delegationUnbondingPeriod
                  currentEpoch
                  epochCount
                
                  
                  totalSignalledTokensTransferredToL2
                  totalTokensSignalledAutoMigrate
                  totalTokensSignalledDirectly
                  totalTokensStakedTransferredToL2
                 
                }
              }`
            
          }
        );
        setTotalTokensSignalledDirectly(response.data.data.graphNetworks[0].totalTokensSignalledDirectly);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTotalTokensSignalledDirectly();
  }, []);

  return <div><h4>Total Tokens Signalled Directly<br></br><NumberFormat value={totalTokensSignalledDirectly/1000000000000000000} displayType={'text'} thousandSeparator={true} decimalScale={false} decimalSeparator={false}/> GRT</h4></div>;

}

export default NewTotalTokensSignalledDirectly;