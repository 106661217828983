import { useState, useEffect } from "react";
import axios from "axios";
import NumberFormat from 'react-number-format';

const easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t);
const lerp = (start, end, t) => start * (1 - t) + end * t;

const AnimatedNumber = ({ startValue, endValue, duration = 2000 }) => {
  const [currentValue, setCurrentValue] = useState(startValue);

  useEffect(() => {
    let startTimestamp;
    let animationFrameId;

    const animate = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = timestamp - startTimestamp;

      const ratio = Math.min(progress / duration, 1);

      const animatedValue = lerp(startValue, endValue, easeInOutQuad(ratio));

      setCurrentValue(Math.floor(animatedValue));

      if (progress < duration) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
      setCurrentValue(endValue);
    };
  }, [endValue, duration, startValue]);

  return <NumberFormat value={currentValue} displayType={'text'} thousandSeparator={true} decimalSeparator={false} />;
};

function ARBTotalDelegatedTokens() {
  const [totalDelegatedTokens, setTotalDelegatedTokens] = useState(0);

  useEffect(() => {
    const fetchTotalDelegatedTokens = async () => {
      try {
        const response = await axios.post(
          "https://api.thegraph.com/subgraphs/name/graphprotocol/graph-network-arbitrum",
          {
            query: 
              `{
                graphNetworks(first: 1, orderBy: epochCount, orderDirection: desc) {
                  curatorCount
                  activeCuratorCount
                  totalTokensAllocated
                  
                  delegatorCount
                  activeDelegatorCount
                  delegationCount
                  activeDelegationCount
                  totalDelegatedTokens
                  
                  indexerCount
                  stakedIndexersCount
                  totalTokensStaked
                
                  
                  totalTokensSignalled
                  
                  subgraphCount
                  subgraphDeploymentCount
                  activeSubgraphCount
                  
                  
                  
                  totalGRTBurned
                  totalGRTMinted
                 
                  totalQueryFees
                  totalSupply
                  
                  assetHolders
                  
                  delegationUnbondingPeriod
                  currentEpoch
                  epochCount
                
                  
                  totalSignalledTokensTransferredToL2
                  totalTokensSignalledAutoMigrate
                  totalTokensSignalledDirectly
                  totalTokensStakedTransferredToL2
                 
                }
              }`
            
          }
        );
        const NewTotalDelegatedTokens = (response.data.data.graphNetworks[0].totalDelegatedTokens/1000000000000000000);
        setTotalDelegatedTokens(NewTotalDelegatedTokens);

      } catch (error) {
        console.error(error);
      }
    };

    fetchTotalDelegatedTokens();
  }, []);

  return (
    <div>
      <h4>Total Delegated Tokens<br />
        <AnimatedNumber startValue={0} endValue={totalDelegatedTokens} /> GRT
      </h4>
    </div>
  );
}

export default ARBTotalDelegatedTokens;