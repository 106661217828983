import { useState, useEffect } from "react";
import '../../../App.css';
import axios from "axios";
import { createQueryFactory } from "../graphQLQuery";
import { URLS } from "../config-urls";
import { getDaysInRange } from '../dateParser';

// import EthereumLogo from '../../../ETHLogo.png'; 
// import ArbitrumLogo from '../../../ARBLogo.png'; 

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function MonitorCompareActiveIndexers() {
  const [dataETH, setDataETH] = useState(0);
  const [dataARB, setDataARB] = useState(0);

  useEffect(() => {
    const fetchDataETH = async () => {
      try {        
        const queryParams = {
          queryName : 'graphNetworks',
          parameters : 'first: 1, orderBy: epochCount, orderDirection: desc',
          body : 'stakedIndexersCount'
        };

        const queryBuilder = createQueryFactory(queryParams);
        
        const res = await axios.post(URLS.GRAPH_QL_MAINNET,
          { 
            query: queryBuilder
          });
        setDataETH(res.data.data.graphNetworks[0].stakedIndexersCount);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataETH();
  }, []);

  useEffect(() => {
    const fetchDataARB = async () => {
      try {        
        const queryParams = {
          queryName : 'graphNetworks',
          parameters : 'first: 1, orderBy: epochCount, orderDirection: desc',
          body : 'stakedIndexersCount'
        };

        const queryBuilder = createQueryFactory(queryParams);
        
        const res = await axios.post(URLS.GRAPH_QL_ARBITRUM,
          { 
            query: queryBuilder
          });
        setDataARB(res.data.data.graphNetworks[0].stakedIndexersCount);
      } catch (error) {
        console.error(error);
      }
    };

    fetchDataARB();
  }, []);

  const [Now] = getDaysInRange(28,7);

  const options = {
    chart: {
      height: '60%',
      backgroundColor: {
        radialGradient: {
          cx: 0.5,
          cy: 0.5,
          r: 0.75
        },
        stops: [
          [0, Highcharts.color('#1B0D33').brighten(0.1).get('rgb')],
          [1, Highcharts.color('#09091F').get('rgb')]
        ]
      },
      type: 'column',
      // events: {
      //   load: function () {
      //     // Render Ethereum logo next to Ethereum label
      //     this.renderer
      //       .image(EthereumLogo, this.plotLeft + this.xAxis[0].toPixels(0) + 5, this.plotTop + this.plotHeight + 30, 30, 30)
      //       .add();
      //     // Render Arbitrum One logo next to Arbitrum label
      //     this.renderer
      //       .image(ArbitrumLogo, this.plotLeft + this.xAxis[0].toPixels(1) - 5, this.plotTop + this.plotHeight + 30, 30, 30)
      //       .add();
      //   },
      // },
    },
    title: {
      style: {
        color: '#d8d2e4',
        fontWeight: 'bold',
        fontSize: '2rem'
      },
      text : 'Active Indexers',
      align: 'center',
      y: 30,
    },
    subtitle: {
      style: {
        color: '#8a8098',
        fontSize: '0.8rem',
      },
      text: 'www.GRTDataHub.com',
      align: 'center',
      subtitleX: 0,
      subtitleY: 25,
      verticalAlign: 'bottom',
    },
    accessibility: {
      announceNewData: {
        enabled: true
      }
    },
    yAxis: {
      tickInterval: ([dataETH + dataARB] / 5),
      min: [0],
      title: {
        text: false,
      },
      labels: {
        formatter: function () {
          if (this.value > 999999999) return Highcharts.numberFormat(this.value / 1000000000, 0) + " B";
          if (this.value > 999999) return Highcharts.numberFormat(this.value / 1000000, 0) + " M"; 
          if (this.value > 999) return Highcharts.numberFormat(this.value / 1000, 0) + " K"; 
          return Highcharts.numberFormat(this.value,0);
        },
        format: '{value}',
        style: {
          fontSize: '1rem',
          textAlign: 'center',
          stops: [0, Highcharts.color('#d8d2e4').brighten(0.2).get('rgb')],
        }
      }
    },
    xAxis: {
      categories: ['Ethereum', 'Arbitrum One'],
      labels: {
        formatter: function () {
          return this.value;
        },
        style: {
          fontSize: '0.9rem',
          textAlign: 'center',
          fontWeight: 'bold',
          color: '#d8d2e4',
          stops: [0, Highcharts.color('#1B0E34').brighten(0.2).get('rgb')],
        }
      },
      title: {
        text: false,
      },
      crosshair: false,
    },
    series: [
      {
        formatter: function () {
          return Highcharts.dateFormat('%d %b %Y', this.value);
        },
        name: 'Active Indexers',
        color: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 0.9 },
          stops: [
            [0, Highcharts.color('#6D49FB').brighten(0).get('rgb')],
            [1, Highcharts.color('#09091F').setOpacity(0).get('rgba')]
          ]
        },
        data: [dataETH, dataARB],
      },
    ],
    credits: {
      style: {
        display: 'none',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: { 
        dataLabels: {
          y: 35,
          x: 0,
          enabled: true,
          crop: false,
          overflow: 'none',
          color: 'white',
          borderWidth: 2,
          style: {
            fontSize: '0.8rem',
          },
        },
        borderRadius: 3,
        borderColor: '#6D49FB', 
        borderWidth: 2,
      }
    },
    tooltip: {
      hideDelay: 200,
      formatter: function () {
        return (
          '<b>' +
          this.y +
          ' </b>' +
          this.series.name +
          ' on <b>' +
          Highcharts.dateFormat('%d %b %Y', this.x) +
          ' ' +
          Highcharts.dateFormat('%d %b %Y', Now) +
          '</b>'
        );
      },
    },
    exporting: {
      className: 'barMobileOne',
      enabled: true,
      buttons: {
        contextButton: {
          text: 'Download',
          fill: '#22143B',
          symbol: 'download',
          menuItems: null,
          onclick: function () {
            this.exportChart();
          },
          theme: {
            fill: 'white',
            states: {
              hover: {
                fill: 'transparent',
                stroke: 'white',
              },
            },
          },
        },
      },
    },
  };

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default MonitorCompareActiveIndexers;
