import React, { useState, useEffect } from "react";
import FlashcardsNavbar from '../navbars/FlashcardsNavbar';

const difficultyOptions = [ 
  { value: 'easy', label: 'Easy' },
  { value: 'medium', label: 'Medium' },
  { value: 'hard', label: 'Hard' },
];

function FlashcardsForm({
  topic,
  difficulty,
  topicOptions,
  handleTopicChange,
  handleDifficultyChange,
  handleStartClick,
}) {
  const DEFAULT_TOPIC = "";
  const DEFAULT_DIFFICULTY = "";

  const [formComplete, setFormComplete] = useState(false);
  const [difficultySelected, setDifficultySelected] = useState(false);
  const [topicSelected, setTopicSelected] = useState(false);

  const handleStartClickButton = (e) => {
    e.preventDefault();
    if (topic !== DEFAULT_TOPIC && difficulty !== DEFAULT_DIFFICULTY) {
      handleStartClick();
    }
  };
  
  useEffect(() => {
    if (topic !== DEFAULT_TOPIC && difficulty !== DEFAULT_DIFFICULTY) {
      setFormComplete(true);
    } else {
      setFormComplete(false);
    }
  }, [topic, difficulty]);
  

  const handleSelectDifficulty = (e) => {
    const selectedDifficulty = e.target.value;
  
    if (selectedDifficulty === "random") {
      const randomIndex = Math.floor(Math.random() * difficultyOptions.length);
      const randomDifficulty = difficultyOptions[randomIndex].value;
      setDifficultySelected(selectedDifficulty !== DEFAULT_DIFFICULTY);
      handleDifficultyChange(randomDifficulty);
    } else {
      setDifficultySelected(selectedDifficulty !== DEFAULT_DIFFICULTY);
      handleDifficultyChange(selectedDifficulty);
    }
  };
  
  const handleSelectTopic = (e) => {
    const selectedTopic = e.target.value;
    setTopicSelected(selectedTopic !== DEFAULT_TOPIC);
    handleTopicChange(selectedTopic);
  };
  

  return (
    <div className="privacy-policy">
    <FlashcardsNavbar></FlashcardsNavbar>
    <form className="quiz-form">
      <h2 className="text-center quiz-title flashcards-title">Welcome to the Flashcards page!</h2>
      <h5 className="text-center quiz-subtitle">
        Here, you can challenge yourself with flashcards on various Advocate-related topics to test your existing knowledge. If you come across a question you're unsure of, don't worry! We provide guidance to the right resources where you can find the answer and enhance your understanding for future quizzes.
      </h5>
      <br />
      <br />
      <div>
        {!topicSelected && (
          <div className="form-group">
            <label htmlFor="topic" className="quiz-form-label">
              Please select a Topic.
            </label>
          </div>
        )}
        <br />
        <div className="form-group">
        <select
                className="form-control quiz-form-input"
                id="topic"
                value={topic}
                onChange={handleSelectTopic}
                required
              >
                <option value={DEFAULT_TOPIC}>Select a topic</option>
                <option value="codeOfConduct">Code of Conduct</option>
                <option value="communityCare" disabled>Community Care</option>
                <option value="ContentCreator" disabled>Content Creator</option>
                <option value="EventEvangelist" disabled>Event Evangelist</option>
                <option value="TechnicalTeacher" disabled>Technical Teacher</option>
                <option value="TextTranslator" disabled>Text Translator</option>
              </select>
        </div>
        <br />
        {!difficultySelected && topicSelected && (
          <div className="form-group">
              <label htmlFor="difficulty" className="quiz-form-label">
                Please select a level of Difficulty.
              </label>
            </div>
             )}
             <br />
              {topicSelected &&  (
            <div className="form-group">
               <select
                className="form-control quiz-form-input"
                id="difficulty"
                value={difficulty}
                onChange={handleSelectDifficulty}
                required
              >
                <option value={DEFAULT_DIFFICULTY}>Select a difficulty</option>
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
                {/* <option value="random">Random</option> */}
              </select>
          </div>   
          )}
        <br />
        <br />
        {topicSelected && difficultySelected && (
            <div className="text-center Quiz-button QB-about-page-subtitle">
            <a className="quiz-link" disabled={!formComplete} onClick={handleStartClickButton}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Start
            </a>
          </div>
        )}
      </div>
    </form>
    </div>
  );
}

export default FlashcardsForm;
