import React from 'react';
import '../../App.css';
import MonitorCompareDelegatorBarChartsCarousel from './MonitorCompareDelegatorBarChartsCarousel';
import MonitorCompareIndexerBarChartsCarousel from './MonitorCompareIndexerBarChartsCarousel';
import MonitorCompareCuratorBarChartsCarousel from './MonitorCompareCuratorBarChartsCarousel';
import MonitorCompareSubgraphBarChartsCarousel from './MonitorCompareSubgraphBarChartsCarousel';

class MonitorBarCharts extends React.Component {
  render() {
      function reveal() {
        var reveals = document.querySelectorAll(".reveal");
      
        for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 50;
      
          if (elementTop < windowHeight - elementVisible) {
            reveals[i].classList.add("active");
          } else {
            reveals[i].classList.remove("active");
          }
        }
      }
      
      window.addEventListener("scroll", reveal);
  return (
      <div className="monitor-config">
        <div className="col-lg-12 ">
<br></br><br></br>
<h1 className="data-title">Delegators</h1>
          <br></br>
          <div className="photo-wrap chartImg1">
          <MonitorCompareDelegatorBarChartsCarousel /></div>
          </div>
          <hr className="hr2 reveal"></hr>
          <h2 className="data-title reveal">Indexers</h2>
          <br></br>
          <div className="photo-wrap chartImg1 reveal">
          <MonitorCompareIndexerBarChartsCarousel /></div>
          <hr className="hr2 reveal"></hr>
          <h2 className="data-title reveal">Curators</h2>
          <br></br>
          <div className="photo-wrap chartImg1 reveal">
          <MonitorCompareCuratorBarChartsCarousel /></div>
          <hr className="hr2 reveal"></hr>
          <h2 className="data-title reveal">Subgraphs</h2>
          <br></br>
          <div className="photo-wrap chartImg1 reveal">
          <MonitorCompareSubgraphBarChartsCarousel /></div>
          <br></br>
        </div>
        
  )
}};

export default MonitorBarCharts;