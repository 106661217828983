import React, { useState, useEffect } from 'react';
import '../../App.css';

import GRTDataHubAllLogosHorizontal3 from "../../GRTDataHub-AllLogosHorizontal-3.png";
import EthereumLogo from "../../ETHLogo.png";
import ArbitrumOneLogo from "../../ARBLogo.png";

import MobileBarCharts from "../mainBarCharts/MobileBarCharts";
// import MobileLineGraphs from "../mainLineGraphs/MobileLineGraphs";
import MonitorBarCharts from "../mainBarCharts/MonitorBarCharts";
// import MonitorLineGraphs from "../mainLineGraphs/MonitorLineGraphs";
import Footer from "../footer/footer";

import NewActiveCuratorCount from "../helpers/overview/NewActiveCuratorCount";
import NewCuratorCount from "../helpers/overview/NewCuratorCount";
import NewTotalTokensSignalled from "../helpers/overview/NewTotalTokensSignalled";
import NewDelegatorCount from "../helpers/overview/NewDelegatorCount";
import NewActiveDelegatorCount from "../helpers/overview/NewActiveDelegatorCount";
import NewDelegationCount from "../helpers/overview/NewDelegationCount";
import NewActiveDelegationCount from "../helpers/overview/NewActiveDelegationCount";
import NewTotalDelegatedTokens from "../helpers/overview/NewTotalDelegated";
import NewIndexerCount from "../helpers/overview/NewIndexerCount";
import NewActiveIndexerCount from "../helpers/overview/NewActiveIndexerCount";
import NewTotalTokensStaked from "../helpers/overview/NewTotalTokensStaked";
import NewTotalIndexingIndexerRewards from "../helpers/overview/NewTotalIndexingIndexerRewards";
import NewTotalIndexerQueryFeeRebates from "../helpers/overview/NewTotalIndexerQueryFeeRebates";
import NewTotalIndexerQueryFeesCollected from "../helpers/overview/NewTotalIndexerQueryFeesCollected";

import NewSubgraphCount from "../helpers/overview/NewSubgraphCount";
import NewActiveSubgraphCount from "../helpers/overview/NewActiveSubgraphCount";
import NewSubgraphDeploymentCount from "../helpers/overview/NewSubgraphDeploymentCount";
import NewCurrentEpoch from "../helpers/overview/NewCurrentEpoch";
import NewDelegationUnbondingPeriod from "../helpers/overview/NewDelegationUnbondingPeriod";
import NewTotalSupply from "../helpers/overview/NewTotalSupply";

import NewTotalQueryFees from "../helpers/overview/NewTotalQueryFees";     
import NewTotalGRTBurned from "../helpers/overview/NewTotalGRTBurned";
import NewTotalGRTMinted from "../helpers/overview/NewTotalGRTMinted";
import NewTotalGRTWithdrawn from "../helpers/overview/NewTotalGRTWithdrawn";
import NewTotalGRTDeposited from "../helpers/overview/NewTotalGRTDeposited";  

import NewTotalSignalledTokensTransferredToL2 from '../helpers/overview/NewTotalSignalledTokensTransferredToL2';
import NewTotalTokensSignalledAutoMigrate from '../helpers/overview/NewTotalTokensSignalledAutoMigrate';
import NewTotalTokensSignalledDirectly from '../helpers/overview/NewTotalTokensSignalledDirectly';
import NewTotalTokensStakedTransferredToL2 from '../helpers/overview/NewTotalTokensStakedTransferredToL2';  
import NewTotalDelegatorQueryFeeRebates from '../helpers/overview/NewTotalDelegatorQueryFeeRebates';
import NewTotalIndexingDelegatorRewards from '../helpers/overview/NewTotalIndexingDelegatorRewards';
import NewTotalCuratorQueryFees from '../helpers/overview/NewTotalCuratorQueryFees';
import NewTotalUnstakedTokensLocked from '../helpers/overview/NewTotalUnstakedTokensLocked';

import ARBDelegatorCount from '../helpers/overview/ARBDelegatorCount';
import ARBActiveDelegatorCount from '../helpers/overview/ARBActiveDelegatorCount';
import ARBDelegationCount from '../helpers/overview/ARBDelegationCount';
import ARBActiveDelegationCount from '../helpers/overview/ARBActiveDelegationCount';
import ARBTotalDelegatedTokens from '../helpers/overview/ARBTotalDelegatedTokens';
import ARBTotalIndexingDelegatorRewards from '../helpers/overview/ARBTotalIndexingDelegtorRewards';
import ARBTotalDelegatorQueryFeeRebates from '../helpers/overview/ARBTotalDelegatorQueryFeeRebates';
import ARBDelegationUnbondingPeriod from '../helpers/overview/ARBDelegationUnbondingPeriod';
import ARBIndexerCount from '../helpers/overview/ARBIndexerCount';
import ARBActiveIndexerCount from '../helpers/overview/ARBActiveIndexerCount';
import ARBTotalTokensStaked from '../helpers/overview/ARBTotalTokensStaked';
import ARBTotalIndexingIndexerRewards from '../helpers/overview/ARBTotalIndexingIndexerRewards';
import ARBTotalIndexerQueryFeeRebates from '../helpers/overview/ARBTotalIndexerQueryFeeRebates';
import ARBTotalIndexerQueryFeesCollected from '../helpers/overview/ARBTotalIndexerQueryFeesCollected';
import ARBCuratorCount from '../helpers/overview/ARBCuratorCount';
import ARBActiveCuratorCount from '../helpers/overview/ARBActiveCuratorCount';
import ARBTotalTokensSignalled from '../helpers/overview/ARBTotalTokensSignalled';
import ARBTotalCuratorQueryFees from '../helpers/overview/ARBTotalCuratorQueryFees';
import ARBTotalTokensSignalledAutoMigrate from '../helpers/overview/ARBTotalTokensSignalledAutoMigrate';
import ARBTotalTokensSignalledDirectly from '../helpers/overview/ARBTotalTokensSignalledDirectly'; 
import ARBSubgraphCount from '../helpers/overview/ARBSubgraphCount';
import ARBSubgraphDeploymentCount from '../helpers/overview/ARBSubgraphDeploymentCount';
import ARBActiveSubgraphCount from '../helpers/overview/ARBActiveSubgraphCount';
import ARBTotalGRTMinted from '../helpers/overview/ARBTotalGRTMinted';
import ARBTotalGRTBurned from '../helpers/overview/ARBTotalGRTBurned';
import ARBTotalUnstakedTokensLocked from '../helpers/overview/ARBTotalUnstakedTokensLocked';
import ARBTotalSupply from '../helpers/overview/ARBTotalSupply';
import ARBCurrentEpoch from '../helpers/overview/ARBCurrentEpoch';
import ARBTotalQueryFees from '../helpers/overview/ARBTotalQueryFees';
// font awesome 
// import { 
  // faTwitter, faTelegramPlane, faReddit, faYoutube, 
  // faEthereum } from "@fortawesome/free-brands-svg-icons";
// import  {ActiveDelegatorCountData, ActiveDelegationCountData, HoldersCountData, SubgraphsCountData, CuratorsCountData, ActiveIndexerCountData, TotalGRTDelegatedByDelegatorsData, TotalQueryFeesCountData, TotalGRTSignalledByCuratorsData,  TotalStakedGRTCountData, TotalDelegatedGRTByIndexersCountData, TotalGRTBurnedCountData} from "./BarChartsData";
// import { YoutubeVideo } from '../YoutubeVideo';
// import YoutubeSubscibeButton from "../YoutubeSubscribeButton";
import ChatBotLogo from "../../GRTDataHubChatBotLink.png";
import AdvocateReady from"../../AdvocateReady.png";
        
const Main = () => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [selectedNetwork, setSelectedNetwork] = useState('SELECT');

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const handleNetworkChange = (network) => {
    setSelectedNetwork(network);
  };

  useEffect(() => {
  }, [selectedNetwork]);

  return (
    <div className={`header-wrapper ${isImageLoaded ? 'loaded' : ''}`}>
       {!isImageLoaded && <div className="loading-message">Loading...</div>}
        <div className="main-info">
          <div className="chatbot-link">
            <a href="/chatBot"><img className="chatbot-link-logo" src={ChatBotLogo}  target="_blank" alt="ChatBot Logo"></img></a>
            </div>
            <div className="chatbot-link">
            <a href="/advocateReady"><img className="advocate-link-logo" src={AdvocateReady}  target="_blank" alt="AdvocateReady Logo"></img></a>
            </div>

          <div className="community-logo-div">
          <img className="community-logo" src={GRTDataHubAllLogosHorizontal3} alt="GRT Data Hub Communities" onLoad={handleImageLoad}></img>

            {/* <span className="telegram"><a href="https://t.me/GraphtronautsDataHub" target="_blank" rel="noreferrer"><h3 className="telegram-icon"><FontAwesomeIcon className="icon" icon={faTelegramPlane} color="#53466C" size="lg" /></h3></a></span>
            <span className="twitter"><a href="https://twitter.com/GRTCrypto" target="_blank" rel="noreferrer"><h3 className="twitter-icon"><FontAwesomeIcon className="icon" icon={faTwitter} color="#53466C" size="lg" /></h3></a></span> */}
            {/* <span className="reddit"><a href="https://www.reddit.com/user/bitcoin_zpt?utm_medium=android_app&utm_source=share" target="_blank" rel="noreferrer"><h3 className="reddit-icon"><FontAwesomeIcon className="icon" icon={faReddit} color="#53466C" size="lg"/></h3></a></span>
                <span className="youtube"><a href="https://youtube.com/channel/UCfWakD5bnrOD_mq8uXlNnbg" target="_blank" rel="noreferrer"><h3 className="youtube-icon"><FontAwesomeIcon className="icon" icon={faYoutube} color="#53466C" size="lg"/></h3></a></span> */}
          </div>
          {isImageLoaded && (
            <>
          <h1 className="header-sub-text">We work closely with the above communities from around the world to share the GRT data on a daily basis with photo and video content provided.<br></br><br></br>Visit our <a className="home-link" href="/aboutPage">about</a> page to find out more and the links to connect with the above communities.</h1>
          <br></br><br></br>

          <div class="header-sub-text">
          <h1 className="data-title"><strong>Network Overview</strong></h1>
          <div className="form-group">
          <select className="form-control quiz-form-input" onChange={(e) => handleNetworkChange(e.target.value)}>
        <option value="SELECT">Select a Network</option>
          <option value="ETH">Ethereum</option>
          <option value="ARB">Arbitrum One</option>
          <option value="COMPARE">Compare Networks</option>
        </select>
          </div>
        
          {selectedNetwork === 'SELECT' && (
          <>
          <div className="network-logo-position">
            <img className="network-logo" src={EthereumLogo}  target="_blank" alt="Ethereum Logo"></img>
            <img className="network-logo" src={ArbitrumOneLogo}  target="_blank" alt="Arbitrum One Logo"></img>
            </div>
          </>
        )}
          {selectedNetwork === 'ETH' && (
          <>
          <div>
            <img className="network-logo" src={EthereumLogo}  target="_blank" alt="Ethereum Logo"></img>
            </div>
            <hr></hr>
          <h1 className="data-title">Delegators</h1>
          <br></br>
          <NewDelegatorCount />
          <NewActiveDelegatorCount />
          <NewDelegationCount />
          <NewActiveDelegationCount />
          <NewTotalDelegatedTokens />
          <NewTotalTokensStakedTransferredToL2 />
          <NewTotalIndexingDelegatorRewards />
          <NewTotalDelegatorQueryFeeRebates />
          <NewDelegationUnbondingPeriod />
          <hr className="hr2"></hr>
          <h2 className="data-title">Indexers</h2>
          <br></br>
          <div>
          <NewIndexerCount />
          <NewActiveIndexerCount />
          <NewTotalTokensStaked />
          <NewTotalIndexingIndexerRewards />
          <NewTotalIndexerQueryFeeRebates />
          <NewTotalIndexerQueryFeesCollected />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Curators</h2>
          <br></br>
          <div>
          <NewCuratorCount />
          <NewActiveCuratorCount />
          <NewTotalTokensSignalled />
          <NewTotalCuratorQueryFees />
          <NewTotalTokensSignalledAutoMigrate />
          <NewTotalSignalledTokensTransferredToL2 />
          <NewTotalTokensSignalledDirectly />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Subgraphs</h2>
          <br></br>
          <div>
          <NewSubgraphCount />
          <NewSubgraphDeploymentCount />
          <NewActiveSubgraphCount />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Token Supply</h2>
          <br></br>
          <div>
          <NewTotalGRTMinted />
          <NewTotalGRTBurned />
          <NewTotalUnstakedTokensLocked />
          <NewTotalSupply /> 
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">More data</h2>
          <br></br>
          <div>
          <NewTotalQueryFees />
          <NewCurrentEpoch />
          <NewTotalGRTWithdrawn />
          <NewTotalGRTDeposited />
          </div>
          </>
        )}
        {selectedNetwork === 'ARB' && (
          <>
           <div>
            <img className="network-logo" src={ArbitrumOneLogo}  target="_blank" alt="Arbitrum One Logo"></img>
            </div>
            <hr></hr>
           <h1 className="data-title">Delegators</h1>
          <br></br>
          <ARBDelegatorCount/>
          <ARBActiveDelegatorCount />
          <ARBDelegationCount />
          <ARBActiveDelegationCount />
          <ARBTotalDelegatedTokens />
          <ARBTotalIndexingDelegatorRewards />
          <ARBTotalDelegatorQueryFeeRebates />
          <ARBDelegationUnbondingPeriod />
          <hr className="hr2"></hr>
          <h2 className="data-title">Indexers</h2>
          <br></br>
          <div>
          <ARBIndexerCount/>
          <ARBActiveIndexerCount />
          <ARBTotalTokensStaked />
          <ARBTotalIndexingIndexerRewards />
          <ARBTotalIndexerQueryFeeRebates />
          <ARBTotalIndexerQueryFeesCollected />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Curators</h2>
          <br></br>
          <div>
          <ARBCuratorCount />
          <ARBActiveCuratorCount />
          <ARBTotalTokensSignalled />
          <ARBTotalCuratorQueryFees />
          <ARBTotalTokensSignalledAutoMigrate />
          <ARBTotalTokensSignalledDirectly />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Subgraphs</h2>
          <br></br>
          <div>
          <ARBSubgraphCount />
          <ARBSubgraphDeploymentCount />
          <ARBActiveSubgraphCount />
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">Token Supply</h2>
          <br></br>
          <div>
          <ARBTotalGRTMinted />
          <ARBTotalGRTBurned />
          <ARBTotalUnstakedTokensLocked />
          <ARBTotalSupply /> 
          </div>
          <hr className="hr2"></hr>
          <h2 className="data-title">More data</h2>
          <br></br>
          <div>
          <ARBTotalQueryFees />
          <ARBCurrentEpoch />
          </div>
         
          </>
        )}

        {selectedNetwork === 'COMPARE' && (
          <>
          <div className="compare-logo-position">
          <img className="network-logo" src={EthereumLogo}  target="_blank" alt="Ethereum Logo"></img><img className="network-logo" src={ArbitrumOneLogo}  target="_blank" alt="Arbitrum One Logo"></img>
            </div>
          <hr></hr>
          <MobileBarCharts />
          <MonitorBarCharts /> 
          {/* <MobileLineGraphs />
          <MonitorLineGraphs /> */}
          </>
          
        )}
     </div>
     <br></br>
          {/* <img className="dataHub-logos" src={GraphtronautsDataHubLogos}  alt="Graphtronauts Data Hub Logos"></img> */}
          {/* <div className="video">
              <YoutubeVideo />
              </div>      */}
          {/* <div className="g-ytsubscribe">
              <YoutubeSubscibeButton/> 
              </div>                             */}

          {/* Data For Mobile Start*/}

          {/* <div class="message no-show-monitor">
              <ActiveDelegatorCountData />
            </div>

            <div class="message no-show-monitor">
            <ActiveDelegationCountData />
            </div>

            <div class="message no-show-monitor">
            <HoldersCountData />
            </div>
            
            <div class="message no-show-monitor">
            <SubgraphsCountData />
            </div>
           
            <div class="message no-show-monitor">
            <CuratorsCountData />
            </div>

            <div class="message no-show-monitor">
            <ActiveIndexerCountData />
            </div>
           
            <div class="message no-show-monitor">
            <TotalGRTDelegatedByDelegatorsData />
            </div>
            
            <div class="message no-show-monitor">
            <TotalQueryFeesCountData />
            </div>
            
            <div class="message no-show-monitor">
            <TotalGRTSignalledByCuratorsData />
            </div>
           
            <div class="message no-show-monitor">
            <TotalStakedGRTCountData />
            </div>

            <div class="message no-show-monitor">
            <TotalDelegatedGRTByIndexersCountData />
            </div>

            <div class="message no-show-monitor">
            <TotalGRTBurnedCountData />
            </div> */}
            
          {/* Data For Mobile End */}
        
            {/* <div class="home-message no-show-monitor">
              Please rotate your device or view on a larger screen size to see data in bar chart and line graph format.
            </div> */}
          <div>
          <h3 className="extra-data">Visit <a className="data-link" href="https://dapplooker.com/browse/dashboards?sort=new&pg=1&q=Graph" target="_blank" rel="noreferrer">DappLooker</a> for in-depth GRT data analytics. Check out the <a className="data-link" href="https://thegraph.com/explorer/network" target="_blank" rel="noreferrer">Graph Explorer Activity</a> or</h3>
          <h3 className="extra-data2">to view the live <a className="data-link" href="https://thegraph.com/explorer/network/epochs" target="_blank" rel="noreferrer">Epoch Data</a>.</h3></div>
          <Footer />
          </>
          )}
        </div>
      </div>
    );
  };

export default Main;