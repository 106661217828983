import React, { useState } from 'react';
import ItemInformation from './ItemInformation';
import './SearchFilter.css';

const SearchFilter = ({ data }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    const value = event.target.value;
    setQuery(value);
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
  };

  const selectedItems = data.filter((item) => {
    const words = query.toLowerCase().split(' ');
    return words.some((word) => item.toLowerCase().includes(word));
  });

  return (
    <div>
      <div className="central">
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleInputChange}
        />
      </div>

      <hr />

      {query.length > 0 &&
        selectedItems.map((selectedItem, index) => (
          <ItemInformation key={index} selectedItem={selectedItem} />
        ))}
    </div>
  );
};

export default SearchFilter;
